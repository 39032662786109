import KWNavbar from '../../components/navbar/navbar'
import KWFooter from '../../components/footer/footer'
import ReCAPTCHA from 'react-google-recaptcha';
import './ScheduleShowing.css';
import swal from 'sweetalert';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import config from '../../config.json'
// import axios from 'axios';

// import { useHistory } from 'react-router-dom';

import { useNavigate } from 'react-router-dom';

export const ScheduleShowing = () => {

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const userId = queryParams.get('uid');
    console.log('userId : ' + userId)
    const [captcha, setCaptcha] = useState(false);
    const navigate = useNavigate()

    // const location = useLocation();
    const [propertyData, setPropertyData] = useState([]);
    const [unitData, setUnitData] = useState({});
    const handleCaptcha = (val) => {
        val ? setCaptcha(true) : setCaptcha(false);
    }

    const [scheduleData, setScheduleData] = useState({
        // "recipientEmail": config.emails.property_booking_request.to, // ITV changes
        // "cc": config.emails.property_booking_request.cc, // ITV changes
        // "mailSubject": config.emails.property_booking_request.subject, // ITV changes
        "property": '',
        "unitID": '',
        "name": '',
        "email": '',
        "phone": '',
        "option1": "",
        "option2": "",
        "option3": ""
    });


    const onSubmit = () => {

        const apiUrl = config.apiUrl + '/v1/mailsend/propertyBookingRequest';

        var myHeaders = new Headers();

        myHeaders.append("Content-Type", "application/json");

        // Convert the input string to a Date object
        const option1InputDate = new Date(scheduleData.option1);
        const option2InputDate = new Date(scheduleData.option2);
        const option3InputDate = new Date(scheduleData.option3);

        // Format the Date object to the desired output format
        const formattedDateTimeString1 = option1InputDate.toLocaleString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            timeZoneName: 'short',
            timeZone: 'America/Toronto', // Use the specific timezone offset, in this case, Eastern Time (EST/EDT)
        });
        // Format the Date object to the desired output format
        const formattedDateTimeString2 = option2InputDate.toLocaleString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            timeZoneName: 'short',
            timeZone: 'America/Toronto', // Use the specific timezone offset, in this case, Eastern Time (EST/EDT)
        });
        // Format the Date object to the desired output format
        const formattedDateTimeString3 = option3InputDate.toLocaleString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            timeZoneName: 'short',
            timeZone: 'America/Toronto', // Use the specific timezone offset, in this case, Eastern Time (EST/EDT)
        });

        scheduleData.option1 = formattedDateTimeString1;
        scheduleData.option2 = formattedDateTimeString2;
        scheduleData.option3 = formattedDateTimeString3;

        var raw = JSON.stringify(scheduleData);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(`${apiUrl}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status === 200) {
                    swal('', result.res, "success");
                    setScheduleData({
                        "property": '',
                        "unitID": '',
                        "name": '',
                        "email": '',
                        "phone": '',
                        "option1": "",
                        "option2": "",
                        "option3": ""
                    })
                }
                else {
                    swal('', result.res, "warning");
                }
            })
            .catch(error => {
                console.log('error', error)
                swal('Opps...', 'Something went wrong.', "warning");
            })
    }

    useEffect(() => {
        if (!userId) {
            const apiUrl = config.apiUrl + '/v1/rentals/units/listings';

            fetch(apiUrl, {
                method: 'GET'
            }).then(response => response.json())
                .then(responseData => {
                    setPropertyData(responseData)
                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                });
        } else {
            const apiUrl = config.apiUrl + `/v1/rentals/units/${userId}/listing`;

            fetch(apiUrl, {
                method: 'GET'
            }).then(response => {
                if (response.status === 200) {
                    return response.json()
                } else {
                    navigate('/');
                }
            }).then(responseData => {
                setUnitData(responseData)
                setScheduleData({
                    // "recipientEmail": config.emails.property_booking_request.to, // ITV changes
                    // "cc": config.emails.property_booking_request.cc, // ITV changes
                    // "mailSubject": config.emails.property_booking_request.subject, // ITV changes
                    "name": '',
                    "email": '',
                    "phone": '',
                    "option1": "",
                    "option2": "",
                    "option3": "",
                    "property": responseData.Property?.Name,
                    "unitID": responseData.Unit?.Id,
                })
            }).catch(error => {
                console.error('Error fetching data:', error);
            });
        }
    }, [navigate])
    const handleChange = (e) => {
        const name = e.target.name;
        let value = e.target.value;

        setScheduleData((pre) => {
            return {
                ...pre,
                [name]: value
            }
        })
    }
    useEffect(() => {
        setScheduleData((pre) => {
            return {
                ...pre,
                ['unitID']: scheduleData.property.substring(Number(scheduleData.property.indexOf('/')) + 1, scheduleData.property.length)
            }
        })
    }, [scheduleData.property])

    return (
        <>
            <KWNavbar></KWNavbar>
            <div className="container mt-5 pt-5">
                <div className="mt-5">
                    <div className="ScheduleShowing-header">WANT TO SEE THIS PROPERTY?</div>
                    <div className="ScheduleShowing-top-para">
                        Please fill out and submit the form below with your information and your preferred dates/times for a showing.We will do our best to accomodate your schedule!
                    </div>
                </div>
                <div className="ScheduleShowing-form">
                    <div className="ScheduleShowing-input">
                        <label htmlFor="Property">Property</label>
                        {
                            userId ?
                                // <p>{unitData.Property?.Name}</p> 
                                <div className='confirm-property-RgridT'><strong>{unitData.Property?.Name}</strong></div>
                                :
                                (
                                    <select className='required-number required-number2 w-100'
                                        value={scheduleData.property}
                                        onChange={handleChange}
                                        name="property"
                                        id="Property">
                                        <option value="">Select Property</option>
                                        {
                                            propertyData.length > 0 ? (
                                                propertyData.map((data) => {
                                                    const unitNumb = data.Unit?.UnitNumber
                                                    let unitNumber = '';
                                                    if (unitNumb.indexOf('[') > 0) {
                                                        unitNumber = unitNumb.substring(0, unitNumb.indexOf('['))
                                                    } else if (
                                                        unitNumber = unitNumb
                                                    )
                                                        return (
                                                            <>
                                                                <option value={`${data.Property.Name}/${data.Unit.Id}`}>{data.Property.Name} {unitNumber}</option>
                                                            </>
                                                        )
                                                })
                                            ) : ''
                                        }
                                    </select>
                                )
                        }
                    </div>
                    <div className="ScheduleShowing-input">
                        <label htmlFor="YourName">Your Name*</label>
                        <input type="text"
                            value={scheduleData.name}
                            onChange={handleChange}
                            className='required-number required-number2 w-100'
                            name="name"
                            id="YourName" />
                    </div>
                    <div className="ScheduleShowing-input">
                        <label htmlFor="Phone">Phone*</label>
                        <input type="text"
                            value={scheduleData.phone}
                            onChange={handleChange}
                            className='required-number required-number2 w-100'
                            name="phone"
                            id="Phone" />
                    </div>
                    <div className="ScheduleShowing-input">
                        <label htmlFor="EmailAddress">Email Address*</label>
                        <input type="text"
                            value={scheduleData.email}
                            onChange={handleChange}
                            className='required-number required-number2 w-100'
                            name="email"
                            id="EmailAddress" />
                    </div>
                    <div className="ScheduleShowing-input">
                        <label htmlFor="EmailAddress" style={{ opacity: "0" }}>Email Address*</label>
                        <div className="ScheduleShowing-Pleaseprovide">
                            <b>Date and Time of Availability:</b> <br />
                            <div>
                                (Please provide a couple of options if possible and we will do our best to accomodate you)
                            </div>
                        </div>
                    </div>
                    <div className="ScheduleShowing-input">
                        <label htmlFor="EmailAddress">Option #1</label>
                        <div>
                            <input value={scheduleData.option1} onChange={handleChange} className='form-control' type="datetime-local" name="option1" id="" />
                        </div>
                    </div>
                    <div className="ScheduleShowing-input">
                        <label htmlFor="EmailAddress">Option #2</label>
                        <div>
                            <input value={scheduleData.option2} onChange={handleChange} className='form-control' type="datetime-local" name="option2" id="" />
                        </div>
                    </div>
                    <div className="ScheduleShowing-input">
                        <label htmlFor="EmailAddress">Option #3</label>
                        <div>
                            <input value={scheduleData.option3} onChange={handleChange} className='form-control' type="datetime-local" name="option3" id="" />
                        </div>
                    </div>

                    <div className='ScheduleShowing-input'>
                        <label htmlFor="EmailAddress" style={{ opacity: "0" }}>Email Address*</label>
                        <div>
                            <div className='mt-3 mb-1 rechaptcha-text'>To protect this website from spam, please check the box below.</div>
                            <ReCAPTCHA
                                sitekey={config.reCaptchaKey}
                                onChange={handleCaptcha}
                            />
                            <div className="mt-4">
                                <button
                                    onClick={() => window.history.back()}
                                    type='button' className='btn btn-secondary CPgridButton1'>Back</button>
                                {
                                    captcha ?
                                        <button
                                            onClick={onSubmit}
                                            type='button'
                                            className='btn btn-primary ms-1 CPgridButton2'>Submit</button>
                                        : <button
                                            onClick={() => swal("!", "Please check the captcha", "warning")}
                                            type='button'
                                            className='btn btn-primary ms-1 CPgridButton2'>Submit</button>
                                }
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <KWFooter></KWFooter>
        </>
    )
}