import React, { useEffect, useState } from 'react'
import { Collapse, Row } from 'react-bootstrap'
import Form from 'react-bootstrap/Form';
import KWNavbar from '../../components/navbar/navbar'
import KWFooter from '../../components/footer/footer'
import './stapp.css'
import img from "../GeneralAplication/download.png"
import config from '../../config.json';
import swal from 'sweetalert';
import { RotatingLines } from 'react-loader-spinner';
import { IoIosCloseCircleOutline } from "react-icons/io";

export const StudentApplication = () => {
    const [isSubmiting, setIsSubmiting] = useState(false)
    const [personalInfo, setPersonalInfo] = useState(false)
    const [importantNote1, setimportantNote1Info] = useState(true)
    const [RHCL, setRHCL] = useState(false)
    const [RHPL, setRHPL] = useState(false)
    const [CoSigner, setCoSigner] = useState(false)
    const [Other, setOther] = useState(false)
    const [ApplDAta, setApplDAta] = useState(false)
    const [isOfferedRentAmountChecked, setisOfferedRentAmountchecked] = useState(false);
    const [youViewedisOn, setyouViewedisOn] = useState(false);
    const [isHaveyouviewed, setHaveyouviewed] = useState('');
    const [isTermsAccepted, setIsTermsAccepted] = useState(false);
    const [Viewingcomments, setViewingcomments] = useState('');
    const [NameOfOtherApplicants, setNameOfOtherApplicants] = useState('');
    const [NumberOfDogs, setNumberOfDogs] = useState('');
    const [NumberOfCats, setNumberOfCats] = useState('');
    const [PleaseListAnyOther, setPleaseListAnyOther] = useState('');
    const [countFile, setFileCount] = useState([])
    const [countFileCoSigner, setFileCountCoSigner] = useState([])
    const [previousLandlordsEmailAddress, setpreviousLandlordsEmailAddress] = useState('')
    const [OtherPleaseDescribeYourCurrentLocation, setOtherPleaseDescribeYourCurrentLocation] = useState('')
    const [Haveyougivennotice, setHaveyougivennotice] = useState(false)

    const [isUnitChecked, setisUnitChecked] = useState(false);
    const [isOn, setIsOn] = useState(false);

    const [CoSignerApplicant, setCoSignerApplicant] = useState({});

    const handleInputChange = (val, key) => {
        setCoSignerApplicant({
            ...CoSignerApplicant,
            [key]:val
        });
        console.log(CoSignerApplicant);
    }

    const handleOfferedRentAmountSwitchChange = () => {
        setisOfferedRentAmountchecked(!isOfferedRentAmountChecked);
        setyouViewedisOn(!youViewedisOn);
    };

    const handleHaveyouviewed = (event) => {
        const { value } = event.target;
        setHaveyouviewed(value == "true");
        setyouViewedisOn(value == "true");
    };

    const handleTermsAccepted = (event) => {
        const { checked } = event.target;
        setIsTermsAccepted(checked == true);
    }

    const handleUnitSwitchChange = () => {
        setisUnitChecked(!isUnitChecked);
        setIsOn(!isOn);
    };

    const [isPetChecked, setisPetChecked] = useState(false);
    const [anypetsisOn, setAnypetsisOn] = useState('');

    const handlePetSwitchChange = (event) => {
        const { value } = event.target
        setisPetChecked(value == 'true');
        setAnypetsisOn(value == 'true');
    };

    const [isBankruptcyChecked, setisBankruptcyChecked] = useState(false);
    const [DebtProposalisOn, setDebtProposalisOn] = useState(false);

    const handleBankruptcySwitchChange = () => {
        setisBankruptcyChecked(!isBankruptcyChecked);
        setDebtProposalisOn(!DebtProposalisOn);
    };

    const [isConsumerDebtProposalChecked, setisConsumerDebtProposalChecked] = useState(false);
    const [filedBankruptcyisOn, setfiledBankruptcyisOn] = useState(false);

    const handleConsumerDebtProposalSwitchChange = () => {
        setisConsumerDebtProposalChecked(!isConsumerDebtProposalChecked);
        setfiledBankruptcyisOn(!filedBankruptcyisOn);
    };

    const [isBillsOnTimeChecked, setisBillsOnTimeChecked] = useState(false);
    const [billsOnisOn, setbillsOnisOn] = useState(false);

    const handleBillsOnTimeSwitchChange = () => {
        setisBillsOnTimeChecked(!isBillsOnTimeChecked);
        setbillsOnisOn(!billsOnisOn);
    };
    const [isAfterYouForChecked, setisAfterYouForhecked] = useState(false);
    const [forMoneyisOn, setforMoneyisOn] = useState(false);

    const handleAfterYouForSwitchChange = () => {
        setisAfterYouForhecked(!isAfterYouForChecked);
        setforMoneyisOn(!forMoneyisOn);
    };
    const [isAcoSignerChecked, setisAcoSignerhecked] = useState('');
    const [coSignerisOn, setcoSignerisOn] = useState('');

    const handleAcoSignerSwitchChange = (event) => {
        const { value } = event.target
        setisAcoSignerhecked(value == 'true');
        setcoSignerisOn(value == 'true');
    };
    const [isApplyingWithChecked, setisApplyingWithhecked] = useState(false);
    const [AgroupisOn, setAgroupisOn] = useState(false);

    const handleApplyingWithSwitchChange = () => {
        setisApplyingWithhecked(!isApplyingWithChecked);
        setAgroupisOn(!AgroupisOn);
    };
    const [birthYear, setBirthYear] = useState()
    const [birthMonth, setBirthMonth] = useState()
    const [birthDay, setBirthDay] = useState()

    const handleCollapse = (from) => {
        if (from === 'importantNote1') {
            setimportantNote1Info(!importantNote1);
        }
        if (from === 'personalInfo') {
            setPersonalInfo(!personalInfo);
        }
        if (from === 'RHCL') {
            setRHCL(!RHCL)
        }
        if (from === 'RHPL') {
            setRHPL(!RHPL)
        }
        if (from === 'Co-Signer') {
            setCoSigner(!CoSigner)
        }
        if (from === 'Other') {
            setOther(!Other)
        }
        if (from === 'ApplDAta') {
            setApplDAta(!ApplDAta)
        }
    }

    let date = new Date();
    const year = function () {
        const yy = [];
        for (let y = date.getFullYear(); y >= 1904; y--) {
            yy.push(y);
        }
        return yy
    }
    const day = function () {
        const dy = [];
        for (var i = 1; i <= 31; i++) {
            dy.push(i);
        }
        return dy
    }
    const month = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const data = year();
    const d = day();
    const [formData, setFormData] = useState({
        "preferredMoveInDate": "",
        "propertyAddress": "",
        "offeredRentAmount": 0,
        "haveYouViewedTheUnit": "",
        "Viewingcomments": Viewingcomments,
        "NumberOfDogs": "",
        "NumberOfCats": "",
        "PleaseListAnyOther": PleaseListAnyOther,
        "requiredNumberOfParkingSpaces": "",
        "familySizeToOccupyHomeAdults": "",
        "familySizeToOccupyHomeChildrens": "",
        "doYouOwnAnyPets": "",
        "currentLocation": "",
        "previousAddress": "",
        "previousLandlordsPhone": "",
        "previousLandlordsFirstName": "",
        "previousLandlordsLastName": "",
        "previousLandlordsEmailAddress": "",
        "CurrentRentAmount": "",
        "ReasonforLeavingCurrentLocation": "",
        "CurrentLandlordsPhone": "",
        "Haveyougivennotice": Haveyougivennotice,
        "CurrentLandlordsFirstName": "",
        "CurrentLandlordsLastName": "",
        "CurrentLandlordsEmailAddress": "",
        "firstName": "",
        "lastName": "",
        "dateOfBirth": "",
        "homePhone": "",
        "cellPhone": "",
        "emailAddress": "",
        "currentAddress": "",
        "currentAddress2": "",
        "applicantdocuments": countFile,
        "city": "",
        "state": "",
        "postal": "",
        "placeOfEmployment": "",
        "occupation": "",
        "EmploymentLengthYears": "",
        "EmploymentLengthMonths": "",
        "employersTelephone": "",
        "hourlyWage": "",
        "grossYearly": "",
        "sinNo": "",
        "numberOfVehiclesOwnedByThisApplicant": "",
        "haveYouEverFiledBankruptcy": "",
        "haveYouEverDoneAConsumerDebtProposal": "",
        "doYouPayYourBillsOnTime": "",
        "hasAnyoneEverComeAfterYouForMoney": "",
        "coSigner": "",
        "areYouApplyingWithAGroup": "",
        "additionalExpectations": "",
        // "recipientEmail": config.emails.application.student.to, // ITV changes
        // "cc": config.emails.application.student.cc, // ITV changes
        "recipientName": "",
        "phone": "",
        "OtherPleaseDescribeYourCurrentLocation": OtherPleaseDescribeYourCurrentLocation,
        // "mailSubject": config.emails.application.student.subject, // ITV changes
        "templateData": {
            "message": "To cancel the booking please call to 0000000000 or you can also cancel the booking by clicking ",
            "link1": "<link>"
        }
    })


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevState) => {
            return {
                ...prevState,
                [name]: value
            }
        })
    }
    useEffect(() => {
        formData.doYouOwnAnyPets = anypetsisOn
        formData.haveYouEverFiledBankruptcy = isBankruptcyChecked
        formData.haveYouEverDoneAConsumerDebtProposal = isConsumerDebtProposalChecked
        formData.doYouPayYourBillsOnTime = isBillsOnTimeChecked
        formData.hasAnyoneEverComeAfterYouForMoney = isAfterYouForChecked
        formData.PleaseListAnyOther = PleaseListAnyOther
        formData.coSigner = isAcoSignerChecked
        formData.areYouApplyingWithAGroup = isApplyingWithChecked
        formData.dateOfBirth = `${birthYear}-${birthMonth?.padStart(2, '0')}-${birthDay?.padStart(2, '0')}`
        formData.haveYouViewedTheUnit = isHaveyouviewed
        formData.OtherPleaseDescribeYourCurrentLocation = OtherPleaseDescribeYourCurrentLocation
        formData.Viewingcomments = Viewingcomments
        formData.NumberOfDogs = NumberOfDogs
        formData.NumberOfCats = NumberOfCats
        formData.NameOfOtherApplicants = NameOfOtherApplicants
        if(isAcoSignerChecked)
            CoSignerApplicant.dateOfBirth = `${CoSignerApplicant.birthYear}-${CoSignerApplicant.birthMonth?.padStart(2, '0')}-${CoSignerApplicant.birthDay?.padStart(2, '0')}`
        console.log(formData);
        console.log(CoSignerApplicant);
    }, [
        isConsumerDebtProposalChecked,
        isBankruptcyChecked,
        isBillsOnTimeChecked,
        isAfterYouForChecked,
        isAcoSignerChecked,
        isApplyingWithChecked,
        AgroupisOn,
        coSignerisOn,
        forMoneyisOn,
        billsOnisOn,
        DebtProposalisOn,
        filedBankruptcyisOn,
        birthDay,
        birthMonth,
        birthYear,
        formData,
        anypetsisOn,
        youViewedisOn,
        isHaveyouviewed,
        CoSignerApplicant,
        PleaseListAnyOther,
        OtherPleaseDescribeYourCurrentLocation,
        Viewingcomments,
        NumberOfDogs,
        NumberOfCats,
        NameOfOtherApplicants
    ])
    // useEffect(() => {
    //     formData.dateOfBirth = `${birthDay}-${birthMonth}-${birthYear}`
    // }, [birthDay, birthMonth, birthYear])
    const handleSubmit = () => {
        var myHeaders = new Headers();
        // myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify(formData);

        const postData = new FormData();
        Object.keys(formData).forEach((fd) => {
          postData.append(fd, formData[fd]);
        });
        Object.keys(CoSignerApplicant).forEach((cf) => {
            postData.append("CoSigner_"+cf, CoSignerApplicant[cf])
        })
        countFile.forEach((c) => {
            postData.append("files", c);
        });
        countFileCoSigner.forEach((c) => {
            postData.append("files", c);
        });

        var requestOptions = {
            method: 'POST',
            // headers: myHeaders,
            body: postData,
            redirect: 'follow'
        };
        if (formData.propertyAddress === '') {
            swal("", "Please specify the property ADDRESS & UNIT # you're interested in", "warning");
            setIsSubmiting(false)
        } else if (formData.haveYouViewedTheUnit === '') {
            swal("!", "Please Select If You Have Viewed The Unit", "warning");
            setIsSubmiting(false)
        } else if (formData.requiredNumberOfParkingSpaces === '') {
            swal("!", "Please Select Required Number Of Parking Spaces", "warning");
            setIsSubmiting(false)
        } else if(formData.doYouOwnAnyPets === '') {
            swal("!", "Please Select If Do You Own Any Pets", "warning");
            setIsSubmiting(false)
        } else if (formData.currentLocation === '') {
            swal("!", "Please Select Current Location", "warning");
            setIsSubmiting(false)
        }
        if (formData.currentLocation === 'renting' && formData.CurrentLandlordsPhone === 10) {
            swal("!", "Please Write Correct Current Landlords Phone", "warning");
            setIsSubmiting(false)
        } else if (formData.currentLocation === 'renting' && formData.CurrentLandlordsFirstName === '') {
            swal("!", "Please Select Current Landlords First Name", "warning");
        } else if (formData.currentLocation === 'renting' && formData.CurrentLandlordsLastName === '') {
            swal("!", "Please Select Current Landlords Last Name", "warning");
            setIsSubmiting(false)
        } else if (formData.currentLocation === 'renting' && formData.CurrentRentAmount === '') {
            swal("!", "Please Select Current Rent Amount", "warning");
            setIsSubmiting(false)
        } else if (formData.currentLocation === 'renting' && formData.ReasonforLeavingCurrentLocation === '') {
            swal("!", "Please Select Reason for Leaving Current Location", "warning");
            setIsSubmiting(false)
        } else if (showotherDetailsForm) {
            if (OtherPleaseDescribeYourCurrentLocation === '') {
                swal("!", "Please Select describe your current location", "warning");
                setIsSubmiting(false)
            }
        } else if (formData.firstName === '') {
            swal("!", "Please Select Applicant Details First Name", "warning");
            setIsSubmiting(false)
        } else if (formData.lastName === '') {
            swal("!", "Please Select Applicant Details Last Name", "warning");
            setIsSubmiting(false)
        } else if (formData.homePhone === 10) {
            swal("!", "Please Select Applicant Details Home Phone", "warning");
            setIsSubmiting(false)
        } else if (formData.cellPhone === 10) {
            swal("!", "Please Select Applicant Details Cell Phone", "warning");
            setIsSubmiting(false)
        } else if (formData.emailAddress === '') {
            swal("!", "Please Select Applicant Details Email Address", "warning");
            setIsSubmiting(false)
        } else if (formData.currentAddress === '') {
            swal("!", "Please Select Applicant Details Current Address", "warning");
            setIsSubmiting(false)
        } else if (formData.city === '') {
            swal("!", "Please Select Applicant Details City", "warning");
            setIsSubmiting(false)
        } else if (formData.state === '') {
            swal("!", "Please Select Applicant Details State", "warning");
            setIsSubmiting(false)
        } else if (formData.postal === '') {
            swal("!", "Please Select Applicant Details Postal", "warning");
            setIsSubmiting(false)
        } else if (formData.placeOfEmployment === '') {
            swal("!", "Please Select Applicant's Place of Employment", "warning");
            setIsSubmiting(false)
        } else if (formData.occupation === '') {
            swal("!", "Please Select Applicant Details Occupation", "warning");
            setIsSubmiting(false)
        } else if (formData.EmploymentLengthYears === '') {
            swal("!", "Please Select Applicant Details Employment Length Years", "warning");
            setIsSubmiting(false)
        } else if (formData.EmploymentLengthMonths === '') {
            swal("!", "Please Select Applicant Details Employment Length Months", "warning");
            setIsSubmiting(false)
        } else if (formData.hourlyWage === '') {
            swal("!", "Please Select Applicant Details Hourly Wage", "warning");
            setIsSubmiting(false)
        } else if (formData.grossYearly === '') {
            swal("!", "Please Select Applicant Details Gross Yearly", "warning");
            setIsSubmiting(false)
        } else if (formData.numberOfVehiclesOwnedByThisApplicant === '') {
            swal("!", "Please Select Applicant Details Number Of Vehicles Owned By This Applicant", "warning");
            setIsSubmiting(false)
        } else if (countFile.length < 3) {
            swal("!", "Please upload at least 3 documents for applicant details", "warning");
            setIsSubmiting(false)
        } else if(formData.coSigner === '') {
            swal("!", "Please select if there will be a co-signer", "warning");
            setIsSubmiting(false)
        } else if(formData.coSigner == true || formData.coSigner == "true") {

            if(CoSignerApplicant?.firstName == undefined || CoSignerApplicant?.firstName == null || CoSignerApplicant?.firstName == "") {
                swal("!", "Please select Co-Signer's First Name", "warning");
                setIsSubmiting(false)
            } else if(CoSignerApplicant?.lastName == undefined || CoSignerApplicant?.lastName == null || CoSignerApplicant?.lastName == "") {
                swal("!", "Please select Co-Signer's Last Name", "warning");
                setIsSubmiting(false)
            } else if(CoSignerApplicant?.birthYear == undefined || CoSignerApplicant?.birthYear == null || CoSignerApplicant?.birthYear == "") {
                swal("!", "Please select Co-Signer's Birth Year", "warning");
                setIsSubmiting(false)
            } else if(CoSignerApplicant?.birthMonth == undefined || CoSignerApplicant?.birthMonth == null || CoSignerApplicant?.birthMonth == "") {
                swal("!", "Please select Co-Signer's Birth Month", "warning");
                setIsSubmiting(false)
            } else if(CoSignerApplicant?.birthDay == undefined || CoSignerApplicant?.birthDay == null || CoSignerApplicant?.birthDay == "") {
                swal("!", "Please select Co-Signer's Birth Day", "warning");
                setIsSubmiting(false)
            } else if(CoSignerApplicant?.cellPhone == undefined || CoSignerApplicant?.cellPhone == null || CoSignerApplicant?.cellPhone == "") {
                swal("!", "Please select Co-Signer's Cell Phone", "warning");
                setIsSubmiting(false)
            } else if(CoSignerApplicant?.emailAddress == undefined || CoSignerApplicant?.emailAddress == null || CoSignerApplicant?.emailAddress == "") {
                swal("!", "Please select Co-Signer's Email Address", "warning");
                setIsSubmiting(false)
            } else if(CoSignerApplicant?.placeOfEmployment == undefined || CoSignerApplicant?.placeOfEmployment == null || CoSignerApplicant?.placeOfEmployment == "") {
                swal("!", "Please select Co-Signer's Place Of Employment", "warning");
                setIsSubmiting(false)
            } else if(CoSignerApplicant?.occupation == undefined || CoSignerApplicant?.occupation == null || CoSignerApplicant?.occupation == "") {
                swal("!", "Please select Co-Signer's Occupation", "warning");
                setIsSubmiting(false)
            } else if(CoSignerApplicant?.EmploymentLengthYears == undefined || CoSignerApplicant?.EmploymentLengthYears == null || CoSignerApplicant?.EmploymentLengthYears == "") {
                swal("!", "Please select Co-Signer's Employment Length in Years", "warning");
                setIsSubmiting(false)
            } else if(CoSignerApplicant?.EmploymentLengthMonths == undefined || CoSignerApplicant?.EmploymentLengthMonths == null || CoSignerApplicant?.EmploymentLengthMonths == "") {
                swal("!", "Please select Co-Signer's Employment Length in Months", "warning");
                setIsSubmiting(false)
            } else if(CoSignerApplicant?.employersTelephone == undefined || CoSignerApplicant?.employersTelephone == null || CoSignerApplicant?.employersTelephone == "") {
                swal("!", "Please select Co-Signer's Telephone", "warning");
                setIsSubmiting(false)
            } else if(CoSignerApplicant?.grossYearly == undefined || CoSignerApplicant?.grossYearly == null || CoSignerApplicant?.grossYearly == "") {
                swal("!", "Please select Co-Signer's Gross Yearly", "warning");
                setIsSubmiting(false)
            } else if(countFileCoSigner.length < 3) {
                swal("!", "Please upload at least 3 documents for co-signer details", "warning");
                setIsSubmiting(false)
            } else {
                if(AgroupisOn == true && formData.NameOfOtherApplicants === '') {
                    swal("!", "Please enter names of other applicants", "warning");
                    setIsSubmiting(false)
                } else {
                    submitStudentApplication(requestOptions);
                }
            }
        } else if(AgroupisOn == true && formData.NameOfOtherApplicants === '') {
            swal("!", "Please enter names of other applicants", "warning");
            setIsSubmiting(false);
        }
         else {
            submitStudentApplication(requestOptions);
        }
        console.log(formData)
    }

    const submitStudentApplication = (requestOptions) => {

        if(!isTermsAccepted) {
            swal("", "Please Accept Terms & Conditions", "warning");
            setIsSubmiting(false)
            return;
        }

        fetch(config.apiUrl + "/v1/application/student", requestOptions)
        .then(res => res.json())
        .then((data) => {
            setIsSubmiting(false)
            if (data.status === 200) {
                setFormData({
                    "preferredMoveInDate": "",
                    "propertyAddress": "",
                    "offeredRentAmount": 0,
                    "haveYouViewedTheUnit": "",
                    "Viewingcomments": "",
                    "NumberOfDogs": "",
                    "NumberOfCats": "",
                    "PleaseListAnyOther": "",
                    "requiredNumberOfParkingSpaces": "",
                    "familySizeToOccupyHomeAdults": "",
                    "familySizeToOccupyHomeChildrens": "",
                    "doYouOwnAnyPets": "",
                    "currentLocation": "",
                    "previousAddress": "",
                    "previousLandlordsPhone": "",
                    "previousLandlordsFirstName": "",
                    "previousLandlordsLastName": "",
                    "previousLandlordsEmailAddress": "",
                    "CurrentRentAmount": "",
                    "ReasonforLeavingCurrentLocation": "",
                    "CurrentLandlordsPhone": "",
                    "Haveyougivennotice": Haveyougivennotice,
                    "CurrentLandlordsFirstName": "",
                    "CurrentLandlordsLastName": "",
                    "CurrentLandlordsEmailAddress": "",
                    "firstName": "",
                    "lastName": "",
                    "dateOfBirth": "",
                    "homePhone": "",
                    "cellPhone": "",
                    "emailAddress": "",
                    "currentAddress": "",
                    "currentAddress2": "",
                    "applicantdocuments": countFile,
                    "city": "",
                    "state": "",
                    "postal": "",
                    "placeOfEmployment": "",
                    "occupation": "",
                    "EmploymentLengthYears": "",
                    "EmploymentLengthMonths": "",
                    "employersTelephone": "",
                    "hourlyWage": "",
                    "grossYearly": "",
                    "sinNo": "",
                    "numberOfVehiclesOwnedByThisApplicant": "",
                    "haveYouEverFiledBankruptcy": "",
                    "haveYouEverDoneAConsumerDebtProposal": "",
                    "doYouPayYourBillsOnTime": "",
                    "hasAnyoneEverComeAfterYouForMoney": "",
                    "coSigner": "",
                    "areYouApplyingWithAGroup": "",
                    "additionalExpectations": "",
                    // "recipientEmail": config.emails.application.student.to, // ITV changes
                    // "cc": config.emails.application.student.cc, // ITV changes
                    "recipientName": "",
                    "phone": "",
                    // "mailSubject": config.emails.application.student.subject, // ITV changes
                    "templateData": {
                        "message": "To cancel the booking please call to 0000000000 or you can also cancel the booking by clicking ",
                        "link1": "<link>"
                    }
                })
                swal(data.res, data.res, "success");
                setIsSubmiting(false)
            } else if (data.status === 400) {
                swal(data.res, data.res, "error");
                setIsSubmiting(false)
            } else {
                swal(data.res, data.res, "error");
                setIsSubmiting(false)
            }
        })
        .catch(error => console.error(error))
    setIsSubmiting(true)
    }
    
    const handleDocuments = (e) => {
        if (countFile.length < 5 && e.target.files.length < 5) {
            setFileCount((prev) => {
                return [...prev,...e.target.files]
            })
        } else {
            swal('', 'Sorry You can\'t Upload more then 5 files', 'info');
        }

    }

    const handleCoSignerDocuments = (e) => {

        if (countFileCoSigner.length < 5 && e.target.files.length < 5) {
            setFileCountCoSigner((prev) => {
                return [...prev,...e.target.files]
            })
        } else {
            swal('', 'Sorry You can\'t Upload more then 5 files', 'info');
        }
        console.log(countFileCoSigner);
    }

    const removeFile = (indexNum) => {
        const newFiles = [...countFile]
        newFiles.splice(indexNum, 1)
        setFileCount(newFiles)
    }

    const removeCoSignerFile = (indexNum) => {
        const newFiles = [...countFileCoSigner]
        newFiles.splice(indexNum, 1)
        setFileCountCoSigner(newFiles)
    }

    useEffect(() => {
        formData.applicantdocuments = countFile
    }, [countFile, formData])


    const [showRentingDetailsForm, setShowRentingDetailsForm] = useState(false)
    useEffect(() => {
        if (formData.currentLocation === 'renting') {
            setShowRentingDetailsForm(true)
        } else {
            setShowRentingDetailsForm(false)
        }
    }, [formData.currentLocation])

    const [showotherDetailsForm, setShowotherDetailsForm] = useState(false)

    useEffect(() => {
        if (formData.currentLocation === 'other') {
            setShowotherDetailsForm(true)
        } else {
            setShowotherDetailsForm(false)
        }
    }, [formData.currentLocation])


    return (
        <>
            {
                isSubmiting ? (
                    <div style={{ position: "fixed", width: "100%", height: "100vh", background: "rgba(0,0,0,0.3)", opacity: "1000" }}
                        className="d-flex justify-content-center"
                    >
                        <RotatingLines
                            strokeColor="grey"
                            strokeWidth="5"
                            animationDuration="0.75"
                            width="96"
                            visible={true}
                        />

                    </div>
                ) : (
                    <>
                        <KWNavbar />
                        <div style={{ backgroundColor: "#eee" }} className='h-100 mt-4 pt-5'>
                            <div className='mt-2 pt-5 container'>
                                <Row>
                                    <div className='col-12 justify-content-center align-items-center m-auto mb-4 student-main-margin' >
                                        <br />
                                        <div className='row border rounded-top'>
                                            <div
                                                style={{ cursor: "pointer", border: "1px solid red" }}
                                                onClick={() => handleCollapse('importantNote1')}
                                                className="form-content p-2 d-flex justify-content-between rounded">
                                                <div>
                                                    <h4 className='py-2 text-danger m-0'>Important Note</h4>
                                                </div>
                                                <div>
                                                    <h2
                                                        onClick={() => handleCollapse('importantNote1')}
                                                        style={{ cursor: "pointer", color: "#A94442" }} className=''>
                                                        {
                                                            importantNote1 ? ("−") : ("+")
                                                        }
                                                    </h2>
                                                </div>
                                            </div>
                                        </div>
                                        <Collapse in={importantNote1}>
                                            <div style={{ backgroundColor: "white" }} className="row p-2 border-2 rounded ">
                                                <div className="h-auto mb-2">
                                                    <div
                                                        style={{ border: "2px solid #8a6d3b" }}
                                                        className="row m-1 d-flex rounded p-3 bg-white">
                                                        <p className='p-1'>
                                                            <img style={{ width: "15px" }} src={img} alt='' />
                                                            <span
                                                                style={{ color: "#8a6d3b" }}
                                                                className='px-1 py-2'>All units require tenant(s) to have an insurance policy at move-in for all leaseholders. If you don't already have an insurance provider, feel free to use our unique link below for a discounted policy : <a href={'https://www.squareone.ca'}>https://www.squareone.ca</a></span>
                                                        </p>
                                                    </div><br />
                                                </div>
                                                <div className="h-auto mb-2">
                                                    <div
                                                        style={{ border: "2px solid #A94442" }}
                                                        className="row m-1 d-flex rounded p-3 bg-white">
                                                        <p className='p-1'>
                                                            <img style={{ width: "15px" }} src={img} alt='' />
                                                            <span
                                                                style={{ color: "#A94442" }}
                                                                className='px-1 py-2'>Please note that all applicants must view the unit in order to process your application. A deposit will be required upon approval of application and instructions will be shared with you at that time.</span>
                                                        </p>
                                                    </div><br />
                                                </div>

                                            </div>
                                        </Collapse>

                                        <br />
                                        <div style={{ backgroundColor: "white" }} className='form-content py-1 px-3 rounded my-4'>
                                            <div className='my-3'>
                                                <div className="form-group my-3">
                                                    <label className='py-2 fw-bold' htmlFor="exampleInputEmail1">Preferred Move in Date</label>
                                                    <input
                                                        type="date"
                                                        value={formData.preferredMoveInDate}
                                                        name='preferredMoveInDate'
                                                        onChange={handleChange}
                                                        className="form-control required-number required-number2 w-100"
                                                        id="exampleInputEmail1"
                                                        aria-describedby="emailHelp"
                                                        placeholder="DD/MM/YYYY" />
                                                </div>
                                                <div className="form-group my-3">
                                                    <label className='py-2 fw-bold' htmlFor="exampleInputPassword1">Please specify the property <span style={{ backgroundColor: "#fcf8e3" }}>ADDRESS & UNIT # </span> you're interested in:<span style={{ fontSize: "16px", color: "red" }}> *</span></label>
                                                    <textarea
                                                        className='form-control required-number required-number2 w-100'
                                                        placeholder='Property address'
                                                        value={formData.propertyAddress}
                                                        onChange={handleChange}
                                                        name="propertyAddress" id="xyz" >

                                                    </textarea>
                                                </div>
                                                <div className="form-group my-3">
                                                    <label className='py-2 fw-bold' htmlFor="exampleInputPassword1">Offered Rent Amount <small style={{ color: "rgba(1,1,1,0.4)", fontSize: "11px" }}> (optional) </small></label>
                                                    <div className="input-group mb-2">
                                                        <span className="input-group-text input-group-text-local">$</span>
                                                        <input
                                                            type="number"
                                                            value={formData.offeredRentAmount}
                                                            name='offeredRentAmount'
                                                            onChange={handleChange}
                                                            className="form-control required-number required-number2"
                                                            id="inlineFormInputGroup"
                                                            placeholder="0.00" />
                                                    </div>
                                                </div>
                                                <div className="form-group my-3">
                                                    <label className='py-2 fw-bold' htmlFor="exampleInputPassword1">Have you viewed the unit?<span style={{ fontSize: "16px", color: "red" }}> *</span></label><br />
                                                        <div className="form-check">
                                                        <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        id="haveYouViewedUnitChecked"
                                                        name="viewedUnit" // Added name attribute for radio group
                                                        value={true}
                                                        onChange={handleHaveyouviewed} // Update handler name
                                                        />
                                                        <label className="form-check-label" htmlFor="haveYouViewedUnitChecked">
                                                        Yes
                                                        </label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        id="haveYouViewedUnitUnchecked" // Unique ID for radio group
                                                        name="viewedUnit" // Added name attribute for radio group
                                                        value={false}
                                                        onChange={handleHaveyouviewed} // Update handler name
                                                        />
                                                        <label className="form-check-label" htmlFor="haveYouViewedUnitUnchecked">
                                                        No
                                                        </label>
                                                    </div>
                                                        {youViewedisOn ?
                                                            <div className="petsForm-Collapse">
                                                                <div>
                                                                    <div><b>Viewing comments: (optional)</b></div>
                                                                    <div className="mt-2">
                                                                        <textarea
                                                                            name="" id=""
                                                                            value={Viewingcomments}
                                                                            onChange={(e) => setViewingcomments(e.target.value)}
                                                                            className='form-control required-number required-number2'
                                                                            placeholder='Add any comments you may have regarding the viewing here.'
                                                                            rows={3}
                                                                            cols={20}
                                                                        ></textarea>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            : ''
                                                        }
                                                </div>
                                            </div>
                                        </div>
                                        <br />

                                        <div className='row border rounded-top'>
                                            <div
                                                style={{ cursor: "pointer" }}
                                                onClick={() => handleCollapse('personalInfo')}
                                                className="form-content p-2 d-flex justify-content-between rounded">
                                                <div>
                                                    <h4 className='py-2'>Personal Information</h4>
                                                </div>
                                                <div>
                                                    <h2
                                                        onClick={() => handleCollapse('personalInfo')}
                                                        style={{ cursor: "pointer" }} className=''>
                                                        {
                                                            personalInfo ? ("−") : ("+")
                                                        }
                                                    </h2>
                                                </div>
                                            </div>
                                        </div>

                                        <Collapse in={personalInfo}>
                                            <div style={{ backgroundColor: "white" }} className="row p-3 border-2 rounded ">
                                                <div className="col-sm-12 col-md-6 h-auto mb-2">
                                                    <label htmlFor="validationCustom01" className='py-2'><b>Required number of parking spaces?</b><span style={{ fontSize: "16px", color: "red" }}> *</span></label>
                                                    <div className="form-group-inline">
                                                        <select
                                                            className="w-100 form-manegement required-number required-number2"
                                                            id="NumberOfVehicles"
                                                            value={formData.requiredNumberOfParkingSpaces}
                                                            onChange={handleChange}
                                                            name="requiredNumberOfParkingSpaces">
                                                            <option value=""></option>
                                                            <option value="0">None</option>
                                                            <option value="1">1</option>
                                                            <option value="2">2</option>
                                                            <option value="3">3</option>
                                                            <option value="4">4</option>
                                                            <option value="5">5</option>
                                                            <option value="6">6</option>
                                                            <option value="7">7</option>
                                                            <option value="8">8</option>
                                                            <option value="9">9</option>
                                                            <option value="10+">10+</option>
                                                        </select>
                                                    </div>
                                                </div>

                                                <div className="form-group my-1">
                                                    <label className='py-2' htmlFor="exampleInputPassword1"><b>Do you own any pets?</b><span style={{ fontSize: "16px", color: "red" }}> *</span></label><br />
                                                            <div className="form-check">
                                                            <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            id="isDoyouOwnAnyChecked"
                                                            name="petOwnership" // Added name attribute for radio group
                                                            value={true}
                                                            onChange={handlePetSwitchChange} // Update handler name
                                                            />
                                                            <label className="form-check-label" htmlFor="isDoyouOwnAnyChecked">
                                                            Yes
                                                            </label>
                                                        </div>
                                                        <div className="form-check">
                                                            <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            id="isDoyouOwnAnyUnchecked" // Unique ID for radio group
                                                            name="petOwnership" // Added name attribute for radio group
                                                            value={false}
                                                            onChange={handlePetSwitchChange} // Update handler name
                                                            />
                                                            <label className="form-check-label" htmlFor="isDoyouOwnAnyUnchecked">
                                                            No
                                                            </label>
                                                        </div>
                                                        {anypetsisOn ?
                                                            <div className="petsForm-Collapse">
                                                                <div className="d-flex">
                                                                    <div className="form-group w-auto">
                                                                        <select
                                                                            class="form-manegement required-number required-number2"
                                                                            id="NumberOfDogs" name="NumberOfDogs"
                                                                            value={NumberOfDogs}
                                                                            onChange={(e) => setNumberOfDogs(e.target.value)}
                                                                        >
                                                                            <option value="0"></option>
                                                                            <option value="0">0</option>
                                                                            <option value="1">1</option>
                                                                            <option value="2">2</option>
                                                                            <option value="3">3</option>
                                                                            <option value="4">4</option>
                                                                            <option value="5">5</option>
                                                                            <option value="6">6</option>
                                                                            <option value="7">7</option>
                                                                            <option value="8">8</option>
                                                                            <option value="9">9</option>
                                                                            <option value="10">10+</option>
                                                                        </select>
                                                                        <label htmlFor="NumberOfDogs" className="ms-2">Dogs</label>
                                                                    </div>
                                                                    <div className="form-group ms-5">
                                                                        <select
                                                                            class="form-manegement required-number required-number2"
                                                                            id="NumberOfCats"
                                                                            name="NumberOfCats"
                                                                            value={NumberOfCats}
                                                                            onChange={(e) => setNumberOfCats(e.target.value)}
                                                                        >
                                                                            <option value="0"></option>
                                                                            <option value="0">0</option>
                                                                            <option value="1">1</option>
                                                                            <option value="2">2</option>
                                                                            <option value="3">3</option>
                                                                            <option value="4">4</option>
                                                                            <option value="5">5</option>
                                                                            <option value="6">6</option>
                                                                            <option value="7">7</option>
                                                                            <option value="8">8</option>
                                                                            <option value="9">9</option>
                                                                            <option value="10">10+</option>
                                                                        </select>
                                                                        <label htmlFor="NumberOfCats" className="ms-2">Cats</label>
                                                                    </div>
                                                                </div>
                                                                <div className="pt-2">
                                                                    <div className="text-dark">Please list any other pets you own
                                                                        <small style={{ color: 'grey' }}>(optional)</small></div>
                                                                    <div className="mt-2 w-100">
                                                                        <textarea
                                                                            name="PleaseListAny" id="PleaseListAny"
                                                                            className='form-control w-100 required-number required-number2'
                                                                            style={{ width: "100%" }}
                                                                            value={PleaseListAnyOther}
                                                                            onChange={(e) => setPleaseListAnyOther(e.target.value)}
                                                                        ></textarea>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            : ''
                                                        }
                                                </div>
                                            </div>
                                        </Collapse>
                                        <br />
                                        <div className='row border rounded-top'>
                                            <div
                                                style={{ cursor: "pointer" }}
                                                onClick={() => handleCollapse('RHCL')}
                                                className="form-content p-2 d-flex justify-content-between rounded">
                                                <div>
                                                    <h4 className='py-2'>Rental History - Current Location<span style={{ fontSize: "26px", color: "red" }}> *</span></h4>
                                                </div>
                                                <div>
                                                    <h2
                                                        onClick={() => handleCollapse('RHCL')}
                                                        style={{ cursor: "pointer" }} className=''>
                                                        {
                                                            RHCL ? ("−") : ("+")
                                                        }
                                                    </h2>
                                                </div>
                                            </div>
                                        </div>
                                        <Collapse in={RHCL}>
                                            <div style={{ backgroundColor: "white" }} className="row mb-4 rounded p-3">
                                                <div className="form-group mb-4">
                                                    <select
                                                        className="w-100 form-manegement rounded py-2 px-1 required-number required-number2"
                                                        id="CurrentLocationStatus"
                                                        value={formData.currentLocation}
                                                        onChange={handleChange}
                                                        name="currentLocation">
                                                        <option value="">Please specify...</option>
                                                        <option value="I am currently a home owner">I am currently a home owner</option>
                                                        <option value="I currently live with family">I currently live with family</option>
                                                        <option value="renting">I am currently renting</option>
                                                        <option value="other">Other</option>
                                                    </select>
                                                </div>
                                                {
                                                    showRentingDetailsForm ? (
                                                        <>
                                                            <div className=''>
                                                                <div className="row">
                                                                    <div className="col-sm-12 col-md-12 col-lg-6">
                                                                        <div className="form-group">
                                                                            <label htmlFor="email">Current Landlord's Phone</label>
                                                                            <div className="input-group mt-1">
                                                                                <span className="input-group-text input-group-text-local">
                                                                                    <img src="https://daks2k3a4ib2z.cloudfront.net/594d3cb202a0d4602f4cc346/595245fcb7fa47031778e519_phone_black.svg"
                                                                                        alt="" className='rentalHPlocation' />
                                                                                </span>
                                                                                <input
                                                                                    className="form-control required-number required-number2"
                                                                                    value={formData.CurrentLandlordsPhone}
                                                                                    onChange={handleChange}
                                                                                    id="CurrentLandlordsPhone"
                                                                                    maxLength={50}
                                                                                    name="CurrentLandlordsPhone"
                                                                                    type="number"
                                                                                    defaultValue=""
                                                                                />
                                                                            </div>
                                                                            <div className="error-msgs" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-sm-12 col-md-12 col-lg-6 CurrentLandlordM">
                                                                        <label htmlFor="CurrentLandlordsFirstName">Current Landlord's Name</label>
                                                                        <div className="row mt-1">
                                                                            <div className="col-sm-12 col-md-6">
                                                                                <input
                                                                                    className="form-control required-number required-number2"
                                                                                    id="CurrentLandlordsFirstName"
                                                                                    value={formData.CurrentLandlordsFirstName}
                                                                                    onChange={handleChange}
                                                                                    name="CurrentLandlordsFirstName"
                                                                                    placeholder="First"
                                                                                    type="text"
                                                                                    defaultValue=""
                                                                                />
                                                                            </div>
                                                                            <div className="col-sm-12 col-md-6 CurrentLandlordM2">
                                                                                {/* <label htmlFor="CurrentLandlordsFirstName" style={{ opacity: "0" }}>Current Landlord's Name</label> */}
                                                                                <input
                                                                                    className="form-control required-number required-number2"
                                                                                    id="CurrentLandlordsLastName"
                                                                                    value={formData.CurrentLandlordsLastName}
                                                                                    onChange={handleChange}
                                                                                    name="CurrentLandlordsLastName"
                                                                                    placeholder="Last"
                                                                                    type="text"
                                                                                    defaultValue=""
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="form-group CurrentLandlordsEmailAddress optional tennant-history-wrapper">
                                                                <label htmlFor="CurrentLandlordsEmailAddress" className='mt-2'>
                                                                    Current Landlord's Email address <sub><small style={{ color: 'grey' }}>(optional)</small></sub>
                                                                </label>
                                                                <input
                                                                    className="form-control required-number required-number2 mt-1"
                                                                    id="CurrentLandlordsEmailAddress"
                                                                    maxLength={80}
                                                                    value={formData.CurrentLandlordsEmailAddress}
                                                                    onChange={handleChange}
                                                                    name="CurrentLandlordsEmailAddress"
                                                                    placeholder="Enter email address"
                                                                    type="email"
                                                                    defaultValue=""
                                                                />
                                                                <div className="error-msgs" />
                                                            </div>
                                                            <div className="form-group">
                                                                <label htmlFor="RentAmount" className='mt-2'>Current Rent Amount</label>
                                                                <div className="input-group mt-2">
                                                                    <span className="input-group-text input-group-text-local">$</span>
                                                                    <input
                                                                        className="form-control required-number required-number2"
                                                                        id="CurrentRentAmount"
                                                                        value={formData.CurrentRentAmount}
                                                                        onChange={handleChange}
                                                                        name="CurrentRentAmount"
                                                                        placeholder={0.0}
                                                                        type="number"
                                                                        defaultValue=""
                                                                    />
                                                                </div>
                                                                <div className="error-msgs" />
                                                            </div>
                                                            <div className="form-group tennant-history-wrapper">
                                                                <label htmlFor="ReasonforLeavingCurrentLocation" className='mt-2'>
                                                                    Reason for Leaving Current Location
                                                                </label>
                                                                <textarea
                                                                    cols={20}
                                                                    id="ReasonforLeavingCurrentLocation"
                                                                    name="ReasonforLeavingCurrentLocation"
                                                                    value={formData.ReasonforLeavingCurrentLocation}
                                                                    onChange={handleChange}
                                                                    rows={3}
                                                                    style={{ width: "100%" }}
                                                                    defaultValue={""}
                                                                    className='form-control required-number required-number2 mt-1'
                                                                />
                                                            </div>
                                                            <div
                                                                className="form-group tennant-history-wrapper mt-2"
                                                                style={{ marginBottom: 40 }}
                                                            >
                                                                <label>Have you given notice?</label>
                                                                <Form>
                                                                    <Form.Check
                                                                        type="switch"
                                                                        id="custom-switch"
                                                                        checked={Haveyougivennotice}
                                                                        onChange={() => setHaveyougivennotice(!Haveyougivennotice)}
                                                                    />
                                                                </Form>
                                                                <div className="error-msgs" />
                                                            </div>
                                                        </>
                                                    ) : ("")
                                                }

                                                {
                                                    showotherDetailsForm ? (
                                                        <div>
                                                            <div className="mt-2">
                                                                <label htmlFor="" className='fw-bold'>Please describe your current location<span style={{ fontSize: "16px", color: "red" }}> *</span></label>
                                                                <input type="text"
                                                                    className='form-control required-number required-number2'
                                                                    name="" id=""
                                                                    // value={formData.OtherPleaseDescribeYourCurrentLocation}
                                                                    // onChange={handleChange}
                                                                    value={OtherPleaseDescribeYourCurrentLocation}
                                                                    onChange={(e) => setOtherPleaseDescribeYourCurrentLocation(e.target.value)}
                                                                />
                                                            </div>
                                                        </div>
                                                    ) : ('')
                                                }
                                            </div>
                                        </Collapse>
                                        <br />
                                        <div className='row border rounded-top'>
                                            <div
                                                style={{ cursor: "pointer" }}
                                                onClick={() => handleCollapse('RHPL')}
                                                className="form-content p-2 d-flex justify-content-between rounded">
                                                <div>
                                                    <h4 className='py-2'>Rental History - Past Location</h4>
                                                </div>
                                                <div>
                                                    <h2
                                                        onClick={() => handleCollapse('RHPL')}
                                                        style={{ cursor: "pointer" }} className=''>
                                                        {
                                                            RHPL ? ("−") : ("+")
                                                        }
                                                    </h2>
                                                </div>
                                            </div>
                                        </div>
                                        <Collapse in={RHPL}>
                                            <div style={{ backgroundColor: "white" }} className="row pb-4 p-3 rounded">
                                                <div className="">
                                                    If you rented in the past 3 years at a location other than your current location, please specify below.
                                                </div>

                                                <div className="form-group">
                                                    <label className='py-1' htmlFor="previousAddress"><b>Previous address</b></label>
                                                    <input
                                                        className="form-control required-number required-number2"
                                                        id="PreviousAddress2" maxlength="255"
                                                        value={formData.previousAddress}
                                                        onChange={handleChange}
                                                        name="previousAddress" type="text" />
                                                </div>
                                                <div className="d-sm-block d-md-flex">
                                                    <div className="col-sm-12 col-md-5">
                                                        <div className="form-group">
                                                            <label className='py-1' htmlFor="PreviousLandlordPhone2"><b>Previous Landlord's Phone</b></label>
                                                            <div className="input-group p-1">
                                                                <span className="input-group-text input-group-text-local">
                                                                    <img src="https://daks2k3a4ib2z.cloudfront.net/594d3cb202a0d4602f4cc346/595245fcb7fa47031778e519_phone_black.svg"
                                                                        alt="" className='rentalHPlocation' />
                                                                </span>
                                                                <input
                                                                    className="form-control required-number required-number2"
                                                                    id="PreviousLandlordPhone"
                                                                    value={formData.previousLandlordsPhone}
                                                                    maxlength="50"
                                                                    onChange={handleChange}
                                                                    name="previousLandlordsPhone"
                                                                    type="number" />
                                                            </div>
                                                            <div className="error-msgs"></div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-12 col-md-7">
                                                        <label className='py-1' htmlFor="LandlordFirstName2"><b>Previous Landlord's Name</b></label>
                                                        <div className="row p-1">
                                                            <div className="col-sm-12 col-md-6 pb-2">
                                                                <input
                                                                    className="form-control required-number required-number2"
                                                                    id="previousLandlordsFirstName" maxlength="25"
                                                                    value={formData.previousLandlordsFirstName}
                                                                    onChange={handleChange}
                                                                    name="previousLandlordsFirstName" placeholder="First" type="text" />
                                                            </div>
                                                            <div className="col-sm-12 col-md-6">
                                                                <input
                                                                    className="form-control required-number required-number2"
                                                                    id="previousLandlordsLastName" maxlength="25"
                                                                    value={formData.previousLandlordsLastName}
                                                                    onChange={handleChange}
                                                                    name="previousLandlordsLastName" placeholder="Last" type="text" />
                                                            </div>
                                                            <div className="error-msgs"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group optional">
                                                    <label className='py-1' htmlFor="LandlordEmailAddress2"><b className='fw-bold'>Previous Landlord's Email address</b> <span style={{ fontSize: "11px", color: "rgba(1,1,1,0.4)" }}>(optional)</span></label>
                                                    <input
                                                        className="form-control required-number required-number2"
                                                        id="previousLandlordsEmailAddress" maxlength="80"
                                                        value={formData.previousLandlordsEmailAddress}
                                                        onChange={handleChange}
                                                        name="previousLandlordsEmailAddress" placeholder="Enter email address" type="email" />
                                                    <div className="error-msgs"></div>
                                                </div>
                                            </div>
                                        </Collapse>
                                        <br />
                                        <div className='row border rounded-top'>
                                            <div
                                                style={{ cursor: "pointer" }}
                                                onClick={() => handleCollapse('ApplDAta')}
                                                className="form-content p-2 d-flex justify-content-between rounded">
                                                <div>
                                                    <h4 className='py-2'>Applicant Details</h4>
                                                </div>
                                                <div>
                                                    <h2
                                                        onClick={() => handleCollapse('ApplDAta')}
                                                        style={{ cursor: "pointer" }} className=''>
                                                        {
                                                            ApplDAta ? ("−") : ("+")
                                                        }
                                                    </h2>
                                                </div>
                                            </div>
                                        </div>
                                        <Collapse in={ApplDAta}>
                                            <div id="applicant-wrapper" style={{ backgroundColor: "white", height: "auto" }} className="row rounded">
                                                <div className="row">
                                                    <div className="col-sm-6">
                                                        <div className="form-group">
                                                            <label htmlFor="App2FirstName" className='fw-bold'>Name</label>
                                                            <div className="row">
                                                                <div className="col-sm-6 mb-2">
                                                                    <input
                                                                        className="form-control required-number required-number2 w-100"
                                                                        id="Applicant_FirstName"
                                                                        maxLength={25}
                                                                        value={formData.firstName}
                                                                        onChange={handleChange}
                                                                        name="firstName"
                                                                        placeholder="First"
                                                                        type="text"
                                                                        defaultValue=""
                                                                        fdprocessedid="cqyqzj"
                                                                    />
                                                                </div>
                                                                <div className="col-sm-6 mb-2">
                                                                    <input
                                                                        className="form-control required-number required-number2 w-100"
                                                                        id="Applicant_LastName"
                                                                        maxLength={25}
                                                                        value={formData.lastName}
                                                                        onChange={handleChange}
                                                                        name="lastName"
                                                                        placeholder="Last"
                                                                        type="text"
                                                                        fdprocessedid="v4zfp"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="error-msgs" />
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div className="form-group">
                                                            <label htmlFor="birthday" className='fw-bold'>Date of Birth</label>
                                                            <div className="d-flex">
                                                                <div className="col mx-1">
                                                                    <select
                                                                        className="form-manegement w-100 rounded-1 required-number required-number2"
                                                                        id="Applicant_BirthYear"
                                                                        value={birthYear}
                                                                        onChange={(e) => setBirthYear(e.target.value)}
                                                                        name="Applicant.BirthYear"
                                                                        fdprocessedid="k0xgaq"
                                                                    >
                                                                        <option value="">YY</option>
                                                                        {
                                                                            data.map((y, index) => {
                                                                                return (
                                                                                    <>
                                                                                        <option key={index} value={y}>{y}</option>
                                                                                    </>
                                                                                )
                                                                            })
                                                                        }
                                                                    </select>
                                                                </div>
                                                                <div className="col mx-1">
                                                                    <select
                                                                        className="form-manegement w-100 rounded-1 required-number required-number2"
                                                                        id="Applicant_BirthMonth"
                                                                        value={birthMonth}
                                                                        onChange={(e) => setBirthMonth(e.target.value)}
                                                                        name="Applicant.BirthMonth"
                                                                        fdprocessedid="tqtrbk"
                                                                    >
                                                                        <option value="">MM</option>
                                                                        {
                                                                            month.map((name, index) => {
                                                                                return <><option value={index + 1}>{name}</option></>
                                                                            })
                                                                        }
                                                                    </select>
                                                                </div>
                                                                <div className="col mx-1">
                                                                    <select
                                                                        className="w-100 form-manegement  required-number required-number2"
                                                                        id="Applicant_BirthDay"
                                                                        value={birthDay}
                                                                        onChange={(e) => setBirthDay(e.target.value)}
                                                                        name="Applicant.BirthDay"
                                                                        fdprocessedid="a6p3i"
                                                                    >
                                                                        <option value="">DD</option>
                                                                        {
                                                                            d.map((d, index) => {
                                                                                return <><option key={index} value={d}>{d}</option></>
                                                                            })
                                                                        }
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div className="error-msgs" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-6">
                                                        <div className="form-group">
                                                            <label htmlFor="HomePhone" className='fw-bold'>Home Phone</label>
                                                            <div className="input-group">
                                                                <span className="input-group-text input-group-text-local">
                                                                    <img src="https://daks2k3a4ib2z.cloudfront.net/594d3cb202a0d4602f4cc346/595245fcb7fa47031778e519_phone_black.svg"
                                                                        alt="" className='rentalHPlocation' />
                                                                </span>
                                                                <input
                                                                    className="form-control required-number required-number2 phone-group"
                                                                    id="Applicant_Phone"
                                                                    maxLength={30}
                                                                    value={formData.homePhone}
                                                                    onChange={handleChange}
                                                                    name="homePhone"
                                                                    placeholder="(555)-555-5555"
                                                                    type="number"
                                                                    defaultValue=""
                                                                    fdprocessedid="9wti6s"
                                                                />
                                                            </div>
                                                            <div className="error-msgs" />
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div className="form-group">
                                                            <label htmlFor="CellPhone" className='fw-bold'>Cell Phone</label>
                                                            <div className="input-group">
                                                                <span className="input-group-text input-group-text-local">
                                                                    <img src="https://daks2k3a4ib2z.cloudfront.net/594d3cb202a0d4602f4cc346/595245fcb7fa47031778e519_phone_black.svg"
                                                                        alt="" className='rentalHPlocation' />
                                                                </span>
                                                                <input
                                                                    className="form-control required-number required-number2 phone-group"
                                                                    id="Applicant_CellPhone"
                                                                    maxLength={30}
                                                                    value={formData.cellPhone}
                                                                    onChange={handleChange}
                                                                    name="cellPhone"
                                                                    placeholder="(555)-555-5555"
                                                                    type="number"
                                                                    defaultValue=""
                                                                    fdprocessedid="j93t4d"
                                                                />
                                                            </div>
                                                            <div className="error-msgs" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="form-group required mt-1">
                                                            <label htmlFor="email" className='fw-bold'>Email address</label>
                                                            <div>
                                                                <input
                                                                    className="form-control required-number required-number2 w-100"
                                                                    id="Applicant_EmailAddress"
                                                                    maxLength={80}
                                                                    value={formData.emailAddress}
                                                                    onChange={handleChange}
                                                                    name="emailAddress"
                                                                    placeholder="Enter email address"
                                                                    type="email"
                                                                    defaultValue=""
                                                                    fdprocessedid="t4inew"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group optional">
                                                            <label htmlFor="inputAddress" className='fw-bold'>Current Address <span style={{ fontSize: "16px", color: "red" }}> *</span></label>
                                                            <input
                                                                className="form-control required-number required-number2 w-100"
                                                                id="Applicant_CurrentAddress"
                                                                maxLength={150}
                                                                value={formData.currentAddress}
                                                                onChange={handleChange}
                                                                name="currentAddress"
                                                                placeholder="123 Main St."
                                                                type="text"
                                                                defaultValue=""
                                                                fdprocessedid="nbtgbk"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group optional">
                                                            <label htmlFor="inputAddress2" className='fw-bold'>Current Address 2 <span style={{ fontSize: "11px", color: "rgba(1,1,1,0.4)" }}>(optional)</span></label>
                                                            <input
                                                                className="form-control required-number required-number2 w-100"
                                                                id="Applicant_CurrentAddress2"
                                                                value={formData.currentAddress2}
                                                                maxLength={150}
                                                                onChange={handleChange}
                                                                name="currentAddress2"
                                                                placeholder="Apartment, studio, or floor"
                                                                type="text"
                                                                defaultValue=""
                                                                fdprocessedid="kopl3k"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <label htmlFor="inputCity" className='fw-bold'>City</label>
                                                        <br />
                                                        <input
                                                            className="form-control required-number required-number2 w-100"
                                                            id="Applicant_CurrentCity"
                                                            maxLength={50}
                                                            value={formData.city}
                                                            onChange={handleChange}
                                                            name="city"
                                                            type="text"
                                                            defaultValue=""
                                                            fdprocessedid="x0jqsk"
                                                        />
                                                    </div>
                                                    <div className="col-md-4">
                                                        <label htmlFor="inputState" className='fw-bold'>State</label>
                                                        <input
                                                            className="form-control required-number required-number2 w-100"
                                                            id="Applicant_CurrentProvince"
                                                            maxLength={50}
                                                            value={formData.state}
                                                            onChange={handleChange}
                                                            name="state"
                                                            type="text"
                                                            defaultValue=""
                                                            fdprocessedid="8sm8gg"
                                                        />
                                                    </div>
                                                    <div className="col-sm-12 col-md-2">
                                                        <label htmlFor="inputZip" className='fw-bold'>Postal</label>
                                                        <input
                                                            className="form-control required-number required-number2 w-100"
                                                            id="Applicant_CurrentPostal"
                                                            maxLength={10}
                                                            value={formData.postal}
                                                            onChange={handleChange}
                                                            name="postal"
                                                            type="text"
                                                            defaultValue=""
                                                            fdprocessedid="5aahbb"
                                                        />
                                                        <div className="error-msgs" />
                                                    </div>
                                                    <div className="error-msgs" />
                                                </div>
                                                <div className="row" style={{ marginTop: 40 }}>
                                                    <div className="col-sm-6">
                                                        <div className="form-group">
                                                            <label htmlFor="App2PlaceOfEmployment" className='fw-bold'>Place of Employment</label>
                                                            <div>
                                                                <input
                                                                    className="form-control required-number required-number2 w-100"
                                                                    id="Applicant_PlaceOfEmployment"
                                                                    value={formData.placeOfEmployment}
                                                                    maxLength={80}
                                                                    name="placeOfEmployment"
                                                                    onChange={handleChange}
                                                                    type="text"
                                                                    defaultValue=""
                                                                    fdprocessedid="nn00ff"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div className="form-group">
                                                            <label htmlFor="App2Occupation" className='fw-bold'>Occupation</label>
                                                            <div>
                                                                <input
                                                                    className="form-control required-number required-number2 w-100"
                                                                    id="Applicant_Occupation"
                                                                    maxLength={80}
                                                                    value={formData.occupation}
                                                                    name="occupation"
                                                                    onChange={handleChange}
                                                                    type="text"
                                                                    defaultValue=""
                                                                    fdprocessedid="g89kda"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-6 col-lg-6">
                                                        <div className="form-group">
                                                            <label htmlFor="email" className='fw-bold'>Length of employment</label>
                                                            <div className="d-flex justify-content-between">
                                                                <div className="col-xs-6 col-sm-12 col-lg-6 m-1 error-helper">
                                                                    <div className="d-flex">
                                                                        <input
                                                                            className="required-number required-number2"
                                                                            id="Applicant_EmploymentLengthYears"
                                                                            maxLength={2}
                                                                            onChange={handleChange}
                                                                            value={formData.EmploymentLengthYears}
                                                                            name="EmploymentLengthYears"
                                                                            placeholder="YY"
                                                                            type="number"
                                                                            defaultValue=""
                                                                            fdprocessedid="49bouq"
                                                                        />
                                                                        <label className='LengthOfEmploymentY' htmlFor="email">Years</label>
                                                                        <div className="error-msgs" />
                                                                    </div>
                                                                </div>
                                                                <div className="col-xs-6 col-sm-12 col-lg-6 m-1 error-helper">
                                                                    <div className="d-flex">
                                                                        <input
                                                                            className="required-number required-number2"
                                                                            id="Applicant_EmploymentLengthMonths"
                                                                            value={formData.EmploymentLengthMonths}
                                                                            name="EmploymentLengthMonths"
                                                                            onChange={handleChange}
                                                                            placeholder="MM"
                                                                            type="text"
                                                                            defaultValue=""
                                                                            fdprocessedid="oglyma"
                                                                        />
                                                                        <label className='LengthOfEmploymentY' htmlFor="email">Months</label>
                                                                        <div className="error-msgs" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div className="form-group optional">
                                                            <label htmlFor="App2EmployerPhone" className='fw-bold'>Employer's Telephone <span style={{ fontSize: "11px", color: "rgba(1,1,1,0.4)" }}>(optional)</span></label>
                                                            <div>
                                                                <input
                                                                    className="form-control required-number required-number2 w-100 mt-1"
                                                                    id="Applicant_EmployerPhone"
                                                                    maxLength={80}
                                                                    value={formData.employersTelephone}
                                                                    name="employersTelephone"
                                                                    onChange={handleChange}
                                                                    type="number"
                                                                    fdprocessedid="uiifit"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-3">
                                                        <div className="form-group">
                                                            <label htmlFor="App2HourlyWage" className='fw-bold'>Hourly Wage <span style={{ fontSize: "11px", color: "rgba(1,1,1,0.4)" }}>(optional)</span></label>
                                                            <div className="input-group mb-3 pt-2">
                                                                <span className="input-group-text input-group-text-local">$</span>
                                                                <input
                                                                    type="number"
                                                                    value={formData.hourlyWage}
                                                                    name='hourlyWage'
                                                                    onChange={handleChange}
                                                                    className="form-control required-number required-number2"
                                                                    placeholder="0.00" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-3">
                                                        <div className="form-group">
                                                            <label htmlFor="App2GrossWage" className='fw-bold'>Gross Yearly</label>
                                                            <div className="input-group d-flex mb-3 pt-2">
                                                                <span className="input-group-text input-group-text-local">$</span>
                                                                <input
                                                                    type="number"
                                                                    value={formData.grossYearly}
                                                                    name='grossYearly'
                                                                    onChange={handleChange}
                                                                    className="form-control required-number required-number2"
                                                                    placeholder="0.00" />
                                                            </div>
                                                            <div className="error-msgs" />
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div className="form-group optional">
                                                            <label htmlFor="App2SIN" className='fw-bold'>S.I.N. No. <span style={{ fontSize: "11px", color: "rgba(1,1,1,0.4)" }}>(optional)</span></label>
                                                            <input
                                                                autoComplete="off"
                                                                className="form-control required-number required-number2 w-100"
                                                                id="Applicant_SIN"
                                                                maxLength={15}
                                                                value={formData.sinNo}
                                                                name="sinNo"
                                                                onChange={handleChange}
                                                                type="number"
                                                                fdprocessedid="9da81e"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-3">
                                                        <label htmlFor="" className='fw-bold'>Number of vehicles owned by this applicant?</label>
                                                        <div className="form-group">
                                                            <select
                                                                id="applicant_vehicles"
                                                                value={formData.numberOfVehiclesOwnedByThisApplicant}
                                                                name='numberOfVehiclesOwnedByThisApplicant'
                                                                onChange={handleChange}
                                                                className="form-manegement required-number required-number2 w-100 applicant-vehicles"
                                                                required=""
                                                                fdprocessedid="msnax"
                                                            >
                                                                <option value="" />
                                                                <option value={0}>0</option>
                                                                <option value={1}>1</option>
                                                                <option value={2}>2</option>
                                                                <option value={3}>3</option>
                                                                <option value={4}>4</option>
                                                                <option value={5}>5</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div id="applicant-vehicle-inputs" />
                                                <div className="row">
                                                    <div className="col-sm-6">
                                                        <label className='fw-bold'>Have you ever filed Bankruptcy?</label>
                                                        <Form className='d-flex'>
                                                            <Form.Check
                                                                className='me-1'
                                                                type="switch"
                                                                id="custom-switch"
                                                                checked={isBankruptcyChecked}
                                                                onChange={handleBankruptcySwitchChange}
                                                            /> {filedBankruptcyisOn ? 'Yes' : 'No'}
                                                        </Form>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <label className='fw-bold'>Have you ever done a Consumer Debt Proposal?</label>
                                                        <div className="form-group">
                                                            <Form className='d-flex'>
                                                                <Form.Check
                                                                    className='me-1'
                                                                    type="switch"
                                                                    id="custom-switch"
                                                                    checked={isConsumerDebtProposalChecked}
                                                                    onChange={handleConsumerDebtProposalSwitchChange}
                                                                /> {DebtProposalisOn ? 'Yes' : 'No'}
                                                            </Form>
                                                            <div className="error-msgs" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-6">
                                                        <label className='fw-bold'>Do you pay your bills on time?</label>
                                                        <Form className='d-flex'>
                                                            <Form.Check
                                                                className='me-1'
                                                                type="switch"
                                                                id="custom-switch"
                                                                checked={isBillsOnTimeChecked}
                                                                onChange={handleBillsOnTimeSwitchChange}
                                                            /> {billsOnisOn ? 'Yes' : 'No'}
                                                        </Form>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <label className='fw-bold'>Has anyone ever come after you for money?</label>
                                                        <Form className='d-flex'>
                                                            <Form.Check
                                                                className='me-1'
                                                                type="switch"
                                                                id="custom-switch"
                                                                checked={isAfterYouForChecked}
                                                                onChange={handleAfterYouForSwitchChange}
                                                            /> {forMoneyisOn ? 'Yes' : 'No'}
                                                        </Form>
                                                    </div>
                                                </div>
                                                <div className="row mt-2">
                                                    <div className="col-md-12">
                                                        <label className='fw-bold mb-0 pb-0'>
                                                            Photo ID, Proof of Income, and Credit Report (At least 3 documents required up to 5 max. We accept images and PDF's).{" "}
                                                        </label>
                                                        <div style={{ position: "relative" }} className="form-group">
                                                            <div style={{ height: 0, overflow: "hidden", width: "100%" }}>
                                                                <input
                                                                    type="text"
                                                                    id="applicant-document-count"
                                                                    name="applicant-document-count"
                                                                    defaultValue={0}
                                                                    className="document-count w-100"
                                                                    fdprocessedid="3yk7cj"
                                                                />
                                                            </div>
                                                            <div
                                                                className="filepond--root my-pond w-100 filepond--hopper custom-file"
                                                                id="applicant-documents"
                                                                data-style-button-remove-item-position="left"
                                                                data-style-button-process-item-position="right"
                                                                data-style-load-indicator-position="right"
                                                                data-style-progress-indicator-position="right"
                                                                data-style-button-remove-item-align="false"
                                                                style={{ height: 76, position: "relative" }}
                                                            >
                                                                <input
                                                                    className='custom-file-input filepond--browser'
                                                                    type="file"
                                                                    id="filepond--browser-wk65r38pt"
                                                                    name="applicantdocuments"
                                                                    onChange={handleDocuments}
                                                                    aria-controls="filepond--assistant-wk65r38pt"
                                                                    aria-labelledby="filepond--drop-label-wk65r38pt"
                                                                    accept="image/gif,image/png,image/jpeg,application/pdf" multiple/>
                                                                <div
                                                                    className="filepond--drop-label"
                                                                    style={{ transform: "translate3d(0px, 0px, 0px)", opacity: 1 }}
                                                                >
                                                                    <label
                                                                        className='custom-file-label'
                                                                        htmlFor="filepond--browser-wk65r38pt"
                                                                        id="filepond--drop-label-wk65r38pt"
                                                                        aria-hidden="true"
                                                                    >
                                                                        Drag & Drop your files or{" "}
                                                                        <span className="filepond--label-action" tabIndex={0}>
                                                                            Browse
                                                                        </span>
                                                                    </label>
                                                                </div>

                                                            </div>
                                                            <div className="error-msgs" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row p-2 PDF-LIST">
                                                    {
                                                        countFile.length > 0 ? (
                                                            countFile.map((f, index) => (
                                                                <>
                                                                    <div className='w-100 h-auto d-flex justify-content-between p-2 border m-1 rounded bggreen500'>
                                                                        <h6 className="name">{f.name}</h6>
                                                                        <div className='cursor-pointer'>
                                                                            <IoIosCloseCircleOutline onClick={() => removeFile(index)} size={30} />
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            ))
                                                        ) : ''
                                                    }
                                                </div>
                                            </div>
                                        </Collapse>

                                        <br />
                                        <div className='row border rounded-top'>
                                            <div
                                                style={{ cursor: "pointer" }}
                                                onClick={() => handleCollapse('Co-Signer')}
                                                className="form-content p-2 d-flex justify-content-between rounded">
                                                <div>
                                                    <h4 className='py-2'>Co-Signer</h4>
                                                </div>
                                                <div>
                                                    <h2
                                                        onClick={() => handleCollapse('Co-Signer')}
                                                        style={{ cursor: "pointer" }} className=''>
                                                        {
                                                            CoSigner ? ("−") : ("+")
                                                        }
                                                    </h2>
                                                </div>
                                            </div>
                                        </div>
                                        <Collapse in={CoSigner}>
                                            <div style={{ backgroundColor: "white" }} className="row rounded">
                                                <div className="form-group my-3">
                                                    <label className='py-2' htmlFor="exampleInputPassword1"><b>Will there be a co-signer?</b></label><br />
                                                        <div className="form-check">
                                                        <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        id="isCoSignerAvailableChecked"
                                                        name="hasCoSigner" // Added name attribute for radio group
                                                        value={true}
                                                        onChange={handleAcoSignerSwitchChange} // Update handler name
                                                        />
                                                        <label className="form-check-label" htmlFor="isCoSignerAvailableChecked">
                                                        Yes
                                                        </label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        id="isCoSignerAvailableUnchecked" // Unique ID for radio group
                                                        name="hasCoSigner" // Added name attribute for radio group
                                                        value={false}
                                                        onChange={handleAcoSignerSwitchChange} // Update handler name
                                                        />
                                                        <label className="form-check-label" htmlFor="isCoSignerAvailableUnchecked">
                                                        No
                                                        </label>
                                                    </div>
                                                        {isAcoSignerChecked ?
                                                            <div className="mt-2">
                                                                <div className="row">
                                                                    <div className="col-sm-6">
                                                                        <div className="form-group">
                                                                            <label htmlFor="App2FirstName" className='fw-bold'>Name</label>
                                                                            <div className="row">
                                                                                <div className="col-sm-6 mb-2">
                                                                                    <input
                                                                                        className="form-control required-number required-number2 w-100"
                                                                                        id="Applicant_FirstName"
                                                                                        maxLength={25}
                                                                                        value={CoSignerApplicant?.firstName}
                                                                                        onChange={(event) => handleInputChange(event.target.value,'firstName')}
                                                                                        name="firstName"
                                                                                        placeholder="First"
                                                                                        type="text"
                                                                                        defaultValue=""
                                                                                        fdprocessedid="cqyqzj"
                                                                                    />
                                                                                </div>
                                                                                <div className="col-sm-6 mb-2">
                                                                                    <input
                                                                                        className="form-control required-number required-number2 w-100"
                                                                                        id="Applicant_LastName"
                                                                                        maxLength={25}
                                                                                        value={CoSignerApplicant?.lastName}
                                                                                        onChange={(event) => handleInputChange(event.target.value,'lastName')}
                                                                                        name="lastName"
                                                                                        placeholder="Last"
                                                                                        type="text"
                                                                                        fdprocessedid="v4zfp"
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            <div className="error-msgs" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-sm-6">
                                                                        <div className="form-group">
                                                                            <label htmlFor="birthday" className='fw-bold'>Date of Birth</label>
                                                                            <div className="d-flex">
                                                                                <div className="col mx-1">
                                                                                    <select
                                                                                        className="form-manegement w-100 rounded-1 required-number required-number2"
                                                                                        id="Applicant_BirthYear"
                                                                                        value={CoSignerApplicant?.birthYear}
                                                                                        onChange={(event) => handleInputChange(event.target.value,'birthYear')}
                                                                                        name="Applicant.BirthYear"
                                                                                        fdprocessedid="k0xgaq"
                                                                                    >
                                                                                        <option value="">YY</option>
                                                                                        {
                                                                                            data.map((y, index) => {
                                                                                                return (
                                                                                                    <>
                                                                                                        <option key={index} value={y}>{y}</option>
                                                                                                    </>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                    </select>
                                                                                </div>
                                                                                <div className="col mx-1">
                                                                                    <select
                                                                                        className="form-manegement w-100 rounded-1 required-number required-number2"
                                                                                        id="Applicant_BirthMonth"
                                                                                        value={CoSignerApplicant?.birthMonth}
                                                                                        onChange={(event) => handleInputChange(event.target.value,'birthMonth')}
                                                                                        name="Applicant.BirthMonth"
                                                                                        fdprocessedid="tqtrbk"
                                                                                    >
                                                                                        <option value="">MM</option>
                                                                                        {
                                                                                            month.map((name, index) => {
                                                                                                return <><option value={index + 1}>{name}</option></>
                                                                                            })
                                                                                        }
                                                                                    </select>
                                                                                </div>
                                                                                <div className="col mx-1">
                                                                                    <select
                                                                                        className="form-manegement w-100 rounded-1  required-number required-number2"
                                                                                        id="Applicant_BirthDay"
                                                                                        value={CoSignerApplicant?.birthDay}
                                                                                        onChange={(event) => handleInputChange(event.target.value,'birthDay')}
                                                                                        name="Applicant.BirthDay"
                                                                                        fdprocessedid="a6p3i"
                                                                                    >
                                                                                        <option value="">DD</option>
                                                                                        {
                                                                                            d.map((d, index) => {
                                                                                                return <><option key={index} value={d}>{d}</option></>
                                                                                            })
                                                                                        }
                                                                                    </select>
                                                                                </div>
                                                                            </div>
                                                                            <div className="error-msgs" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-sm-6">
                                                                        <div className="form-group">
                                                                            <label htmlFor="HomePhone" className='fw-bold'>Home Phone</label>
                                                                            <div className="input-group">
                                                                                <span className="input-group-text input-group-text-local">
                                                                                    <img src="https://daks2k3a4ib2z.cloudfront.net/594d3cb202a0d4602f4cc346/595245fcb7fa47031778e519_phone_black.svg"
                                                                                        alt="" className='rentalHPlocation' />
                                                                                </span>
                                                                                <input
                                                                                    className="form-control required-number required-number2 phone-group"
                                                                                    id="Applicant_Phone"
                                                                                    maxLength={30}
                                                                                    value={CoSignerApplicant?.homePhone}
                                                                                        onChange={(event) => handleInputChange(event.target.value,'homePhone')}
                                                                                    name="homePhone"
                                                                                    placeholder="(555)-555-5555"
                                                                                    type="number"
                                                                                    defaultValue=""
                                                                                    fdprocessedid="9wti6s"
                                                                                />
                                                                            </div>
                                                                            <div className="error-msgs" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-sm-6">
                                                                        <div className="form-group">
                                                                            <label htmlFor="CellPhone" className='fw-bold'>Cell Phone</label>
                                                                            <div className="input-group">
                                                                                <span className="input-group-text input-group-text-local">
                                                                                    <img src="https://daks2k3a4ib2z.cloudfront.net/594d3cb202a0d4602f4cc346/595245fcb7fa47031778e519_phone_black.svg"
                                                                                        alt="" className='rentalHPlocation' />
                                                                                </span>
                                                                                <input
                                                                                    className="form-control required-number required-number2 phone-group"
                                                                                    id="Applicant_CellPhone"
                                                                                    maxLength={30}
                                                                                    value={CoSignerApplicant?.cellPhone}
                                                                                    onChange={(event) => handleInputChange(event.target.value,'cellPhone')}
                                                                                    name="cellPhone"
                                                                                    placeholder="(555)-555-5555"
                                                                                    type="number"
                                                                                    defaultValue=""
                                                                                    fdprocessedid="j93t4d"
                                                                                />
                                                                            </div>
                                                                            <div className="error-msgs" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-md-12">
                                                                        <div className="form-group required mt-1">
                                                                            <label htmlFor="email" className='fw-bold'>Email address</label>
                                                                            <div>
                                                                                <input
                                                                                    className="form-control required-number required-number2 w-100"
                                                                                    id="Applicant_EmailAddress"
                                                                                    maxLength={80}
                                                                                    value={CoSignerApplicant?.emailAddress}
                                                                                    onChange={(event) => handleInputChange(event.target.value,'emailAddress')}
                                                                                    name="emailAddress"
                                                                                    placeholder="Enter email address"
                                                                                    type="email"
                                                                                    defaultValue=""
                                                                                    fdprocessedid="t4inew"
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row" style={{ marginTop: 30 }}>
                                                                    <div className="col-sm-6">
                                                                        <div className="form-group">
                                                                            <label htmlFor="App2PlaceOfEmployment" className='fw-bold'>Place of Employment</label>
                                                                            <div>
                                                                                <input
                                                                                    className="form-control required-number required-number2 w-100"
                                                                                    id="Applicant_PlaceOfEmployment"
                                                                                    value={CoSignerApplicant?.placeOfEmployment}
                                                                                    onChange={(event) => handleInputChange(event.target.value,'placeOfEmployment')}
                                                                                    maxLength={80}
                                                                                    name="placeOfEmployment"
                                                                                    type="text"
                                                                                    defaultValue=""
                                                                                    fdprocessedid="nn00ff"
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-sm-6">
                                                                        <div className="form-group">
                                                                            <label htmlFor="App2Occupation" className='fw-bold'>Occupation</label>
                                                                            <div>
                                                                                <input
                                                                                    className="form-control required-number required-number2 w-100"
                                                                                    id="Applicant_Occupation"
                                                                                    maxLength={80}
                                                                                    value={CoSignerApplicant?.occupation}
                                                                                    onChange={(event) => handleInputChange(event.target.value,'occupation')}
                                                                                    name="occupation"
                                                                                    type="text"
                                                                                    defaultValue=""
                                                                                    fdprocessedid="g89kda"
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row pt-3">
                                                                    <div className="col-sm-6">
                                                                        <div className="form-group">
                                                                            <label htmlFor="email" className='fw-bold'>Length of employment</label>
                                                                            <div className="d-flex">
                                                                                <div className="col-xs-6 m-1 error-helper">
                                                                                    <div className="d-flex">
                                                                                        <input
                                                                                            className="form-manegement rounded col-6 required-number required-number2"
                                                                                            id="Applicant_EmploymentLengthYears"
                                                                                            maxLength={2}
                                                                                            value={CoSignerApplicant?.EmploymentLengthYears}
                                                                                            onChange={(event) => handleInputChange(event.target.value,'EmploymentLengthYears')}
                                                                                            name="EmploymentLengthYears"
                                                                                            placeholder="YY"
                                                                                            type="number"
                                                                                            defaultValue=""
                                                                                            fdprocessedid="49bouq"
                                                                                        />
                                                                                        <label className='m-1' htmlFor="email">Years</label>
                                                                                        <div className="error-msgs" />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-xs-6 m-1 error-helper">
                                                                                    <div className="d-flex">
                                                                                        <input
                                                                                            className="form-manegement rounded col-6 required-number required-number2"
                                                                                            id="Applicant_EmploymentLengthMonths"
                                                                                            maxLength={2}
                                                                                            value={CoSignerApplicant?.EmploymentLengthMonths}
                                                                                            onChange={(event) => handleInputChange(event.target.value,'EmploymentLengthMonths')}
                                                                                            name="EmploymentLengthMonths"
                                                                                            placeholder="MM"
                                                                                            type="text"
                                                                                            defaultValue=""
                                                                                            fdprocessedid="oglyma"
                                                                                        />
                                                                                        <label className='m-1' htmlFor="email">Months</label>
                                                                                        <div className="error-msgs" />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-sm-6">
                                                                        <div className="form-group optional">
                                                                            <label htmlFor="App2EmployerPhone" className='fw-bold'>Employer's Telephone <span style={{ fontSize: "11px", color: "rgba(1,1,1,0.4)" }}>(optional)</span></label>
                                                                            <div>
                                                                                <input
                                                                                    className="form-control required-number required-number2 w-100 mt-1"
                                                                                    id="Applicant_EmployerPhone"
                                                                                    maxLength={80}
                                                                                    value={CoSignerApplicant?.employersTelephone}
                                                                                    onChange={(event) => handleInputChange(event.target.value,'employersTelephone')}
                                                                                    name="employersTelephone"
                                                                                    type="number"
                                                                                    fdprocessedid="uiifit"
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row pt-3">
                                                                    <div className="col-sm-3">
                                                                        <div className="form-group">
                                                                            <label htmlFor="App2HourlyWage" className='fw-bold'>Hourly Wage <span style={{ fontSize: "11px", color: "rgba(1,1,1,0.4)" }}>(optional)</span></label>
                                                                            <div className="input-group mb-3">
                                                                                <span className="input-group-text input-group-text-local">$</span>
                                                                                <input
                                                                                    type="number"
                                                                                    value={CoSignerApplicant?.hourlyWage}
                                                                                    onChange={(event) => handleInputChange(event.target.value,'hourlyWage')}
                                                                                    name='hourlyWage'
                                                                                    className="form-control required-number required-number2"
                                                                                    placeholder="0.00" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-sm-3">
                                                                        <div className="form-group">
                                                                            <label htmlFor="App2GrossWage" className='fw-bold'>Gross Yearly</label>
                                                                            <div className="input-group d-flex mb-3">
                                                                                <span className="input-group-text input-group-text-local">$</span>
                                                                                <input
                                                                                    type="number"
                                                                                    value={CoSignerApplicant?.grossYearly}
                                                                                    onChange={(event) => handleInputChange(event.target.value,'grossYearly')}
                                                                                    name='grossYearly'
                                                                                    className="form-control required-number required-number2"
                                                                                    placeholder="0.00" />
                                                                            </div>
                                                                            <div className="error-msgs" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='row mt-2'>
                                                                    <div className="col-md-12">
                                                                        <label className="fw-bold mb-0 pb-0">
                                                                            On who's behalf will you be co-signing?:
                                                                        </label>
                                                                        <textarea
                                                                            value={CoSignerApplicant?.onBehalfOf}
                                                                            onChange={(event) => handleInputChange(event.target.value,'onBehalfOf')}
                                                                            name='onBehalfOf'
                                                                            className="form-control required-number required-number2"
                                                                            placeholder="Please list all names..." />
                                                                    </div>
                                                                </div>
                                                                <div className="row mt-2">
                                                                    <div className="col-md-12">
                                                                        <label className='fw-bold mb-0 pb-0'>
                                                                            Photo ID, Proof of Income, and Credit Report (At least 3 documents required up to 5 max. We accept images and PDF's).{" "}
                                                                        </label>
                                                                        <div style={{ position: "relative" }} className="form-group">
                                                                            <div style={{ height: 0, overflow: "hidden", width: "100%" }}>
                                                                                <input
                                                                                    type="text"
                                                                                    id="cosigner-applicant-document-count"
                                                                                    name="cosigner-applicant-document-count"
                                                                                    defaultValue={0}
                                                                                    className="document-count w-100"
                                                                                    fdprocessedid="3yk7cj"
                                                                                />
                                                                            </div>
                                                                            <div
                                                                                className="filepond--root my-pond w-100 filepond--hopper custom-file"
                                                                                id="cosigner-applicant-documents"
                                                                                data-style-button-remove-item-position="left"
                                                                                data-style-button-process-item-position="right"
                                                                                data-style-load-indicator-position="right"
                                                                                data-style-progress-indicator-position="right"
                                                                                data-style-button-remove-item-align="false"
                                                                                style={{ height: 76, position: "relative" }}
                                                                            >
                                                                                <input
                                                                                    className='custom-file-input filepond--browser'
                                                                                    type="file"
                                                                                    id="filepond--browser-wk65r38ptCS"
                                                                                    name="cosigner-applicantdocuments"
                                                                                    onChange={(event) => handleCoSignerDocuments(event)}
                                                                                    // value={CoSignerApplicant?.firstName}
                                                                                    // onChange={(event) => handleInputChange([...event.target.files],'files')}
                                                                                    aria-controls="filepond--assistant-wk65r38ptCS"
                                                                                    aria-labelledby="filepond--drop-label-wk65r38ptCS"
                                                                                    accept="image/gif,image/png,image/jpeg,application/pdf" multiple/>
                                                                                <div
                                                                                    className="filepond--drop-label"
                                                                                    style={{ transform: "translate3d(0px, 0px, 0px)", opacity: 1 }}
                                                                                >
                                                                                    <label
                                                                                        className='custom-file-label'
                                                                                        htmlFor="filepond--browser-wk65r38ptCS"
                                                                                        id="filepond--drop-label-wk65r38ptCS"
                                                                                        aria-hidden="true"
                                                                                    >
                                                                                        Drag & Drop your files or{" "}
                                                                                        <span className="filepond--label-action" tabIndex={0}>
                                                                                            Browse
                                                                                        </span>
                                                                                    </label>
                                                                                </div>

                                                                            </div>
                                                                            <div className="error-msgs" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row p-2 PDF-LIST">
                                                                    {
                                                                        countFileCoSigner.length > 0 ? (
                                                                            countFileCoSigner.map((f, index) => (
                                                                                <>
                                                                                    <div className='w-100 h-auto d-flex justify-content-between p-2 border m-1 rounded bggreen500'>
                                                                                        <h6 className="name">{f.name}</h6>
                                                                                        <div className='cursor-pointer'>
                                                                                            <IoIosCloseCircleOutline onClick={() => removeCoSignerFile(index)} size={30} />
                                                                                        </div>
                                                                                    </div>
                                                                                </>
                                                                            ))
                                                                        ) : ''
                                                                    }
                                                                </div>
                                                            </div>
                                                            : ''
                                                        }
                                                </div>
                                            </div>
                                        </Collapse>
                                        <br />
                                        <div className='row border rounded-top'>
                                            <div
                                                style={{ cursor: "pointer" }}
                                                onClick={() => handleCollapse('Other')}
                                                className="form-content p-2 d-flex justify-content-between rounded">
                                                <div>
                                                    <h4 className='py-2'>Other</h4>
                                                </div>
                                                <div>
                                                    <h2
                                                        onClick={() => handleCollapse('Other')}
                                                        style={{ cursor: "pointer" }} className=''>
                                                        {
                                                            Other ? ("−") : ("+")
                                                        }
                                                    </h2>
                                                </div>
                                            </div>
                                        </div>
                                        <Collapse in={Other}>
                                            <div style={{ backgroundColor: "white" }} className="row rounded">
                                                <div className="form-group pt-3">
                                                    <label htmlFor="exampleInputPassword1"><b>Are you applying with a group?</b></label><br />
                                                    <div className='mt-2'>
                                                        <Form>
                                                            <Form.Check
                                                                className='me-1'
                                                                type="switch"
                                                                id="custom-switch"
                                                                checked={isApplyingWithChecked}
                                                                onChange={handleApplyingWithSwitchChange}
                                                            /> {AgroupisOn ?
                                                                <div className='YesNoDropdown'>
                                                                    <div className='YesNoButton'>Yes</div>
                                                                    <br />
                                                                    <div className="mt-2">
                                                                        <div>Please enter the names of the other applicants</div>
                                                                        <textarea
                                                                            value={NameOfOtherApplicants}
                                                                            onChange={(e) => setNameOfOtherApplicants(e.target.value)}
                                                                            id="PleaseEnterThe"
                                                                            className='form-control required-number required-number2 w-100 mt-2'
                                                                        >
                                                                        </textarea>
                                                                    </div>
                                                                </div>
                                                                : <div className='YesNoDropdown'>
                                                                    <div className='YesNoButton'>No</div>
                                                                </div>
                                                            }
                                                        </Form>
                                                    </div>
                                                </div>
                                                <div className="form-group my-3">
                                                    <label className='py-2' htmlFor="exampleInputPassword1"><b>Additional Expectations</b></label><br />
                                                    <div className='pb-2'>
                                                        Please note, all units are rented 'as is'. Any alteration requests must be put in writing during the application process and will be submitted to the owner for approval. List any and all additional expectations that you feel need to be addressed prior to moving in. We will do our best to accommodate you, but we do not make any guarantees, whatsoever. Thank-you, The Management.
                                                    </div>
                                                    <div className='mt-2 pb-3'>
                                                        <textarea
                                                            value={formData.additionalExpectations}
                                                            name="additionalExpectations"
                                                            onChange={handleChange}
                                                            id="exampleInputPassword1"
                                                            cols="20" rows="3"
                                                            className='form-control required-number required-number2 w-100'></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                        </Collapse>
                                            <div className='form-group my-3'>
                                            <input
                                                className="form-check-input mx-2"
                                                type="checkbox"
                                                id="isTermsAccepted"
                                                name="termsAccepted" // Added name attribute for radio group
                                                onChange={handleTermsAccepted} // Update handler name
                                                />
                                            <label htmlFor="isTermsAccepted">
                                                I agree to the terms & conditions
                                            </label>
                                            </div>
                                        <div
                                            style={{ border: "2px solid #337ab7" }}
                                            className="row mt-4 d-flex rounded p-3 bg-white">
                                            <p className='p-1'>
                                                {/* <img style={{ width: "15px" }} src={img} alt='' /> */}
                                                <span
                                                    style={{ color: "#333", fontWeight: 'bold', lineHeight: 1.42857143, marginBottom: 5, }}
                                                >To speed up the application process, we require every applicant that is over 18 years old to provide a full credit report, reflecting the score. Please see below for some free & paid websites to pull your own credit report and self-submit. Thank-you, The Management.</span>
                                            </p>
                                            <div className='row text-decoration-none' >
                                                <div className='col-xs-6 col-md-4'><a href="https://www.creditkarma.ca/" target="_blank" rel="noreferrer" style={{ textDecoration: 'none' }}>www.creditkarma.ca</a></div>
                                                <div className='col-xs-6 col-md-4'><a href="https://borrowell.com/" target="_blank" rel="noreferrer" style={{ textDecoration: 'none' }}>www.borrowell.com</a></div>
                                                <div className='col-xs-6 col-md-4'><a href="https://www.mogo.ca/" target="_blank" rel="noreferrer" style={{ textDecoration: 'none' }}>www.mogo.ca</a></div>
                                                <div className='col-xs-6 col-md-4'><a href="https://www.equifax.ca/" target="_blank" rel="noreferrer" style={{ textDecoration: 'none' }}>www.equifax.ca</a></div>
                                                <div className='col-xs-6 col-md-4'><a href="https://www.transunion.ca" target="_blank" rel="noreferrer" style={{ textDecoration: 'none' }}>www.transunion.ca</a></div>
                                            </div>
                                        </div><br />
                                        <div className='my-3'>
                                            <button
                                                onClick={() => {
                                                    setIsSubmiting(true)
                                                    handleSubmit()
                                                }
                                                }
                                                className='btn btn-primary'>Submit Application</button>
                                        </div>

                                    </div>
                                </Row>
                            </div>
                        </div>
                        <KWFooter />
                    </>
                )
            }

        </>
    )
}
