import React, { useEffect, useState } from 'react'
import KWNavbar from '../../components/navbar/navbar'
import KWFooter from '../../components/footer/footer'
import './ServiceRequest.css';
import ReCAPTCHA from 'react-google-recaptcha';
import config from '../../config.json'
import swal from 'sweetalert';
import { IoIosCloseCircleOutline } from "react-icons/io";
import { Form } from 'react-bootstrap';
import axios from 'axios';

const ServiceRequestNew = () => {
    const [isTenantExist, setIsTenantExist] = useState(null)
    const [isForThisServiceCallChecked, setisForThisServiceCallChecked] = useState(true)
    const [isIacknowledgeAndChecked, setIacknowledgeAndChanged] = useState(false)
    const [isTermsAndConditionsChecked, setTermsAndConditionsChecked] = useState(false)
    const [countFile, setFileCount] = useState([])
    const [Captcha, setCaptcha] = useState(true)
    const [email, setEmail] = useState(null)
    const [subCategory, setsubCategory] = useState()
    const [serviceCategory, setServiceCategory] = useState({
        category: []
    })

    const [seviceReqData, setServiceReqData] = useState({
        PropertyId: '',
        CategoryId: '',
        SubCategoryId: '',
        RequestSummary: '',
        UnitId: '',
        TenantId: '',
        Priority: ''
    })

    const IacknowledgeAndChanged = () => {
        setIacknowledgeAndChanged(!isIacknowledgeAndChecked)
    };

    const handleDocumentUploads = (e) => {
        if (countFile.length < 5) {
            setFileCount((prev) => {
                return [...prev, ...e.target.files]
            })
        } else {
            swal('', 'Sorry You can\'t Upload more then 5 files', 'info');
        }
    }
    const TermsAndConditionsChanged = () => {
        setTermsAndConditionsChecked(!isTermsAndConditionsChecked)
    };

    const handleCaptcha = (val) => {
        val ? setCaptcha(true) : setCaptcha(false)
    }

    const verifyTenantEmail = () => {
        axios.post(`http://localhost:3003/api/v1/verify-tenant`, { email: email })
            .then(res => {
                if (res.status == 200) {
                    axios.get(`http://localhost:3003/api/v1/service-category`)
                        .then(r => {
                            setServiceCategory(pr => {
                                return {
                                    ...pr,
                                    category: r.data.data
                                }
                            })

                        })
                        .catch(err => {
                            console.log(err)
                        })
                    setIsTenantExist(res.data.data)
                } else {
                    swal("no tenant exist with the email id " + email)
                }
            }).catch(err => {
                swal("no tenant exist with the email id " + email)
            })
    }


    const removeFile = (indexNum) => {
        const newFiles = [...countFile]
        newFiles.splice(indexNum, 1)
        setFileCount(newFiles)
    }

    const submitWorkOrderFormOnly = () => {
        console.log(seviceReqData)
        console.log(countFile)
        const formData = new FormData()
        Object.keys(seviceReqData).map(k => {
            formData.append(k, seviceReqData[k])
        })
        countFile.map((f) => {
            formData.append('files[]', f)

        })

        console.log(formData)

        axios.post(`http://localhost:3003/api/v1/service-request`, formData)
            .then(d => {
                alert("mail sent!!")
            })
    }

    useEffect(() => {
        console.log(isTenantExist)
        if (isTenantExist != null) {
            setServiceReqData(pr => {
                return {
                    ...pr,
                    PropertyId: isTenantExist.PropertyId,
                    UnitId: isTenantExist.UnitId,
                    TenantId: isTenantExist.TenantId
                }
            })
        }
    }, [isTenantExist, subCategory])

    return (
        <>
            <KWNavbar />
            <div className="serviceRequest-container mt-5 pt-5">
                <div className="mt-5">
                    <div className="service-header">Work Order Request Form</div>
                    <div className="service-para">
                        Please fill out the work order request form and we will respond to your needs quickly and efficiently. If you have any questions or concerns regarding your work order, please don't hesitate to contact us through the means below.
                    </div>
                    <div className="service-H_details">
                        <div className="">Phone : 519-954-8082</div>
                        <div className="">Email : <a href="">maintenance@kwproperty.com</a></div>
                    </div>

                    <div className="service-form">
                        <div className='w100Icenter my-5'>
                            <div>
                                <div>
                                    <input
                                        placeholder='Enter Your Email'
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        className='form-control w-100' type="text" />
                                </div>
                                <div>
                                    <input
                                        value={!isTenantExist ? 'Verify Your Email' : 'Email Verified'}
                                        type="button"
                                        onClick={verifyTenantEmail}
                                        className={`w-100 my-2 btn ${isTenantExist ? 'btn-success' : 'btn-info'}`}
                                    />
                                </div>
                            </div>
                        </div>
                        {
                            isTenantExist ? (
                                <>
                                    <div style={{ alignItems: "center" }} className='d-flex'>
                                        <h4>Property Address : </h4>
                                        <div className='px-2'>{isTenantExist?.PropertyAddress}</div>
                                    </div>
                                    <div className='border rounded p-3 mt-3'>
                                        <div
                                            className='fs-4 justify-content-between d-flex'
                                            data-bs-toggle="collapse"
                                            data-bs-target="#Servicerequired"
                                            style={{ cursor: "pointer" }}
                                        >
                                            <div><b>Work / Service required</b></div>
                                            <div className="">
                                                <img style={{ width: "15px" }} src="https://cdn-icons-png.flaticon.com/512/25/25629.png" alt="" />
                                            </div>
                                        </div>
                                        <div className="collapse" id="Servicerequired">
                                            <div className='mt-2 input-div'>
                                                <div className='service-form-label2' >Service Category</div>
                                                <select onChange={(e) => {
                                                    setsubCategory(serviceCategory.category.filter(r => r.CategoryId == e.target.value))
                                                    setServiceReqData(pr => {
                                                        return {
                                                            ...pr,
                                                            CategoryId: +e.target.value
                                                        }
                                                    })
                                                }} className='form-control'>
                                                    <option value="">------ Select ctegory ------</option>
                                                    {
                                                        serviceCategory.category.map(data => {
                                                            return <option value={data.CategoryId}>{data.CategoryName}</option>
                                                        })
                                                    }

                                                </select>
                                            </div>
                                            {
                                                subCategory?.length ? (
                                                    <div className='mt-2 input-div'>
                                                        <div className='service-form-label2'>Service Sub-category</div>
                                                        <select onChange={(e) => {
                                                            setServiceReqData(pr => {
                                                                return {
                                                                    ...pr,
                                                                    SubCategoryId: +e.target.value
                                                                }
                                                            })
                                                        }} className='form-control'>
                                                            <option value="">------ Select ctegory ------</option>
                                                            {
                                                                subCategory[0]?.SubCategories?.map(data => {
                                                                    console.log(data)
                                                                    return <option value={data.SubCategoryId}>{data.SubCategoryName}</option>
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                ) : (null)
                                            }

                                            <div className="">
                                                <label className='service-form-label' style={{ opacity: 0 }} htmlFor="YourName">Your Name*</label>
                                                <div className="select-div">
                                                    <div className='mb-2 mt-2'><b>Please describe the work or service required:</b></div>
                                                    <div className="PleaseDescribe">
                                                        If you're dealing with an <b style={{ color: "#0c5460" }}>appliance issue</b> please specify the <b style={{ color: "#0c5460" }}>make</b> and <b style={{ color: "#0c5460" }}>model</b> so we can better serve you.
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="">
                                                {/* <label className='service-form-label' style={{ opacity: 0 }} htmlFor="YourName">Your Name*</label> */}
                                                <div>
                                                    <textarea
                                                        onChange={(e) => setServiceReqData(pr => {
                                                            return {
                                                                ...pr,
                                                                RequestSummary: e.target.value
                                                            }
                                                        })}
                                                        value={seviceReqData.RequestSummary} className='service-form-input2 required-number required-number2' name="" id="" cols="20" rows="3" maxLength="250"></textarea>
                                                    <br />
                                                    <small style={{ opacity: 0.6 }}>250 characters remaining</small>
                                                </div>
                                            </div>

                                            <div className="input-div">
                                                <div className='service-form-label2'>Priority :</div>
                                                <select onChange={(e) => {
                                                    setServiceReqData(pr => {
                                                        return {
                                                            ...pr,
                                                            Priority: e.target.value
                                                        }
                                                    })
                                                }} className='form-control'>
                                                    <option value="">------ Select ctegory ------</option>
                                                    <option value="Urgent">Urgent</option>
                                                    <option value="High">High</option>
                                                    <option value="Medium">Medium</option>
                                                    <option value="Low">Low</option>
                                                </select>
                                            </div>
                                            <div className="">
                                                <label className='service-form-label' style={{ opacity: 0 }} htmlFor="YourName">Your Name*</label>
                                                <div className=''>
                                                    <div className='service-form-label2'>Do you need to be home for this service call?</div>
                                                    <div className="ms-2">
                                                        <Form>
                                                            <Form.Check
                                                                type="switch"
                                                                id="custom-switch"
                                                                checked={isForThisServiceCallChecked}
                                                            />
                                                            {
                                                                isForThisServiceCallChecked ?
                                                                    <div className='YesNoDropdown'>
                                                                        <div className='YesNoButton'>Yes</div>
                                                                    </div>
                                                                    : <div className='YesNoDropdown'>
                                                                        <div className='YesNoButton'>No</div>
                                                                    </div>
                                                            }
                                                        </Form>
                                                        {
                                                            !isForThisServiceCallChecked ?
                                                                <div className='alert alert-info mt-2 d-flex'>
                                                                    <Form>
                                                                        <Form.Check
                                                                            type="switch"
                                                                            id="custom-switch"
                                                                            checked={isIacknowledgeAndChecked}
                                                                            onChange={IacknowledgeAndChanged}
                                                                            className='serviceCall_check'
                                                                        />
                                                                    </Form>
                                                                    <div className="fs-6 fw-bold">I acknowledge and allow a tradesperson to enter the premises to carry out the repairs required.</div>
                                                                </div> : ""
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="">
                                                <div class="form-group">
                                                    <div className="FileUploadHeader">Have any photos to show us?</div>
                                                    <div className="row mb-3">
                                                        <div className="col-md-12">
                                                            <div style={{ position: "relative" }} className="form-group">
                                                                <div style={{ height: 0, overflow: "hidden", width: "100%" }}>
                                                                    <input
                                                                        type="text"
                                                                        id="applicant-document-count"
                                                                        name="applicant-document-count"
                                                                        defaultValue={0}
                                                                        className="document-count w-100"
                                                                        fdprocessedid="3yk7cj"
                                                                    />
                                                                </div>
                                                                <div
                                                                    className="filepond--root my-pond w-100 filepond--hopper custom-file"
                                                                    id="applicant-documents"
                                                                    data-style-button-remove-item-position="left"
                                                                    data-style-button-process-item-position="right"
                                                                    data-style-load-indicator-position="right"
                                                                    data-style-progress-indicator-position="right"
                                                                    data-style-button-remove-item-align="false"
                                                                    style={{ height: 76, position: "relative" }}
                                                                >
                                                                    <input
                                                                        className='custom-file-input filepond--browser'
                                                                        type="file"
                                                                        id="filepond--browser-wk65r38pt"
                                                                        name="applicantdocuments"
                                                                        onChange={handleDocumentUploads}
                                                                        aria-controls="filepond--assistant-wk65r38pt"
                                                                        aria-labelledby="filepond--drop-label-wk65r38pt"
                                                                        accept="image/gif,image/png,image/jpeg,application/pdf" multiple />
                                                                    <div
                                                                        className="filepond--drop-label"
                                                                        style={{ transform: "translate3d(0px, 0px, 0px)", opacity: 1 }}
                                                                    >
                                                                        <label
                                                                            className='custom-file-label'
                                                                            htmlFor="filepond--browser-wk65r38pt"
                                                                            id="filepond--drop-label-wk65r38pt"
                                                                            aria-hidden="true"
                                                                        >
                                                                            Drag & Drop your files or{" "}
                                                                            <span className="filepond--label-action" tabIndex={0}>
                                                                                Browse
                                                                            </span>
                                                                        </label>
                                                                    </div>

                                                                </div>
                                                                <div className="error-msgs" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {
                                                        countFile.length > 0 ? (
                                                            countFile.map((f, index) => (
                                                                <>
                                                                    <div className='w-100 h-auto d-flex justify-content-between p-2 border my-2 rounded bggreen500'>
                                                                        <h6 className="name">{f.name}</h6>
                                                                        <div className='cursor-pointer'>
                                                                            <IoIosCloseCircleOutline onClick={() => removeFile(index)} size={30} />
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            ))
                                                        ) : ''
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="border rounded p-3 mt-3">
                                        <div
                                            className='fs-4 justify-content-between d-flex'
                                            data-bs-toggle="collapse"
                                            data-bs-target="#TermsandConditions"
                                            style={{ cursor: "pointer" }}
                                        >
                                            <div><b className='service-form-para2-H'>Terms and Conditions</b></div>
                                            <div className="">
                                                <img style={{ width: "15px" }} src="https://cdn-icons-png.flaticon.com/512/25/25629.png" alt="" />
                                            </div>
                                        </div>
                                        <div className="" id="TermsandConditions">
                                            <div className="">
                                                <label className='service-form-label' style={{ opacity: 0 }} htmlFor="YourName">Your Name*</label>
                                                <div className="service-form-para2">
                                                    <div className="service-form-para2-text">
                                                        Tenants please be advised you will be charged for a service call if the following work order is a result of tenant neglect or damage. Also including below but not limited to:
                                                    </div>
                                                    <div className="service-form-para2-text">
                                                        <b>CLOGGED SINKS -</b> This service call is a charge to the tenants if the pipes are full of debris. The tenant will not be charged if the pipe is structurally compromised
                                                    </div>
                                                    <div className="service-form-para2-text">
                                                        <b>NO HYDRO -</b> The tenant will be charged for this service call if the breakers are off or fuses are burnt out. The tenant will also be charged for this service call if the receptacle reset button needs to be pressed to reset. Please ensure you have checked breakers, fuses and reset buttons prior to our company sending out an electrician. This will avoid costs to you.
                                                    </div>
                                                    <div className="service-form-para2-text">
                                                        FURNACE NOT WORKING - Please make sure the outside vent (exhaust vent) is not clogged by debris or ice or snow. If the HVAC technician arrives on site and determines the cause to be a blocked vent, the tenant will be held responsible for the service call. The tenant will also be held responsible for the service call if the batteries on the thermostat are low, or a dirty furnace prevented the furnace from working (Furnace filters should be changed at least every 3 months).
                                                        <b>
                                                            Make sure Furnace and or AC switch is turned on, or the tenant will be held responsible for the service call.
                                                        </b>
                                                    </div>
                                                    <div className="service-form-para2-text">
                                                        <b>LIGHT BULBS -</b> The tenant is responsible for changing all burnt out light bulbs.
                                                    </div>
                                                    <div className="service-form-para2-text">
                                                        <b>NO ACCESS GRANTED -</b> Please be sure if you request to be home during the service call you are in fact there. If you are not there to grant access after you specifically ask for this service, you will be charged for the service call.
                                                    </div>
                                                    <div className="service-form-para2-text">
                                                        <b>WATER SOFTENER -</b> If your work order is in regards to your water softener running continuously, please put the water softener on bypass by either turning the valve manually or on the program screen selecting bypass. If you cannot determine the bypass please call our office immediately.
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-2 ps-2">
                                        <div className='mb-3'>
                                            <div className='service-form-label2'>I accept these terms and conditions:</div>
                                            <Form>
                                                <Form.Check
                                                    type="switch"
                                                    id="custom-switch"
                                                    checked={isTermsAndConditionsChecked}
                                                    onChange={TermsAndConditionsChanged}
                                                />
                                                {
                                                    isTermsAndConditionsChecked ?
                                                        <div className='YesNoDropdown'>
                                                            <div className='YesNoButton'>Yes</div>
                                                        </div>
                                                        : <div className='YesNoDropdown'>
                                                            <div className='YesNoButton'>No</div>
                                                        </div>
                                                }
                                            </Form>
                                        </div>
                                        <div className="service-form-label2 mb-2">To protect this website from spam, please check the box below.</div>
                                        <div>
                                            <ReCAPTCHA
                                                sitekey={config.reCaptchaKey}
                                                onChange={handleCaptcha}
                                            />
                                        </div>
                                        {
                                            Captcha ?
                                                <div className="mt-3">
                                                    <button type='button' className='Property-Currently-button' onClick={submitWorkOrderFormOnly}>Submit</button>
                                                </div> :
                                                <div className="mt-3">
                                                    <button type='button' className='Property-Currently-button' onClick={() => {
                                                        swal('!', `Sorry! To protect this website from spam, please check the "I'm not a robot" box below. Please try again.`, 'warning')
                                                    }}>Submit</button>
                                                </div>
                                        }
                                    </div>
                                </>
                            ) : ('')
                        }

                    </div>
                </div>
            </div>
            <KWFooter />
        </>
    )
}

export default ServiceRequestNew
