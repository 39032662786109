import KWNavbar from '../../components/navbar/navbar';
import KWFooter from '../../components/footer/footer';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom'
import './BecomeATenent.css'

export const BecomeATenent = () => {
    const navigate = useNavigate();
    return (
      <>
        <KWNavbar></KWNavbar>
        <div className="container become-a-tenant-container mt-5 pt-5">
          {/* ********  Blank Page  ******** */}
          <h1 style={{ textAlign: "center" }} className="mt-5">
            Become A Tenent
          </h1>
          <div className="row">
            <div className="col text-center mt-4">
              <Button
                variant="outline-primary"
                className="me-2"
                onClick={() => {
                  navigate("/student-application");
                }}
              >
                Student Application
              </Button>
              <Button
                variant="outline-primary"
                onClick={() => {
                  navigate("/genaral-application", {
                    state: { name: "Owners" },
                  });
                }}
              >
                General Application
              </Button>
            </div>
          </div>
        </div>
        <KWFooter></KWFooter>
      </>
    );
}