import React, { useState, useEffect } from 'react'
import './Ebook.css'
import { Button, Card, Col, Container, Form, Row } from 'react-bootstrap'
import KWNavbar from '../../components/navbar/navbar'
import KWFooter from '../../components/footer/footer'
import config from '../../config.json'
import axios from 'axios'
import swal from 'sweetalert';
import ReCAPTCHA from 'react-google-recaptcha';
import { useNavigate } from 'react-router-dom'

export const Ebook = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');
  const [cotactData, setContactData] = useState('');
  const [Captcha, setCaptcha] = useState(false)

  const navigate = useNavigate()


  const handleCaptcha = (val) => {
    console.log(val)
    val ? setCaptcha(true) : setCaptcha(false)
  }

  useEffect(() => {
    setContactData({
      // "recipientEmail": config.emails.contact_us.to, // ITV changes
      // "cc": config.emails.contact_us.cc, // ITV changes
      // "mailSubject": config.emails.contact_us.subject, // ITV changes
      "name": name,
      "email": email,
      "phone": phone,
      "message": message
    })
  }, [name, email, phone, message])

  function validateEmail(email) {
    // Regular expression for basic email validation
    var regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
}

  const handleSubmit = () => {
    console.log(name)
    console.log(phone)
    console.log(email)
    console.log(message)

    if (name === '') {
      swal("!", "Name is require!", "warning");
    } else if (phone === '') {
      swal("!", "Phone number is require!", "warning");
    } else if (email === '') {
      swal("!", "Email is require!", "warning");
      if (!validateEmail(email)) {
        console.log("Email is invalid");
        swal("!", "Invalid EmailId!", "warning");
        return;
    }
    } else if (message === '') {
      swal("!", "Your message is require!", "warning");
    } else {

      axios.post(`${config.apiUrl}/v1/mailsend/contactus`, cotactData).then((res) => {

        if (res.data.status === 200) {
          setName('')
          setEmail('')
          setPhone('')
          setMessage('')
          navigate('thankyou')
        }
      })
    }
  }

  return (
    <>
      <KWNavbar />
      <Container className='mt-5 pt-5 content-container'>
        <div>
            <div className='form-content'>
              <h1>Effortless Property Management for <span className='blue'>New Investors</span></h1>
             
              <b> Discover Effortless Property Management with KW Property Management </b>
               <hr />
               <b> Attention New and Novice Investors!</b> Have you recently stepped into the world of real estate investment, or perhaps added another property to your budding portfolio?  
               <p></p>
              <Row>

                <Col sm={12} lg={6} className='pe-'>
                  Before you dive in, equip yourself with the essential knowledge you need in the
                  <b> Property Investor’s Checklist: How to Choose the Perfect Property Management Team. </b>
                  This free, comprehensive guide gives you insider tips and strategies for confidently selecting the ideal property management company for your investment property.
                  <p>
                    <p></p>
                  <ul>
                    <li>
                      <b>Know What It Takes to Succeed:</b> Discover the 7 essential services your property management company must offer, complete with handy checklists for instant clarity!
                    </li>
                    <li>
                      <b>Maximize Your Property Investments:</b> Get the inside scoop on the real pros and cons of hiring a property manager, whether you own one or multiple investment properties.
                    </li>
                    <li>
                      <b>Take Control of Your Investment Future:</b> Be prepared with powerful questions that empower you to interview property management companies with confidence, even if you're a total newcomer to property investment.
                    </li>
                  </ul>
                  </p>
                  <p>Imagine a future where you're backed by a team that drives your property's success. This is your first step. <b>Discover what sets a top-notch property management team apart and how they can elevate your investment property.</b></p>
                  <p><a href="https://www.kwproperty.com/Download/property_investors_checklist.pdf" target="_blank" rel="noreferrer">Get Your FREE Copy of the Property Investor’s Checklist Now!</a></p>


                  <p>
      With KW Property Management by your side, you can be confident that your property is well-maintained, profitable, and in experienced hands, allowing you to focus on expanding your portfolio and maximizing returns.
    </p>
                </Col>
                <Col sm={12} lg={6}>
                <Card className='p-3 form-card d-flex flex-column align-items-center justify-content-center h-100'>
              <Form>

              <h4>DOWNLOAD THE GUIDE FREE</h4>
              <p>Fill in the form below and learn the 7 things to look for in the perfect property management company.</p>
            
                <Form.Group className="mb-3">
                  <Form.Control value={name} name='name' onChange={(e) => setName(e.target.value)} type="text" placeholder="Enter name" />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Control value={phone} type="phone" onChange={(e) => setPhone(e.target.value)} placeholder="Enter your phone number" />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Control value={email} onChange={(e) => setEmail(e.target.value)} type="email" placeholder="Enter your email" />
                </Form.Group>

                

                <Form.Group className="mb-3">
                  <Form.Control value={message} onChange={(e) => setMessage(e.target.value)} as="textarea" placeholder="Enter your message" style={{ height: '100px' }} />
                </Form.Group>
                <div className="mt-2 mb-1" style={{fontSize: "14px",wordSpacing: "1px"}}>To protect this website from spam, please check the box below.</div>
                <ReCAPTCHA
                  sitekey={config.reCaptchaKey}
                  onChange={handleCaptcha}
                />
                 {
                  Captcha ? 

                  <Button onClick={handleSubmit} className='btn btn-primary mt-2'>GET ACCESS</Button>
                  : 
                  <Button onClick={() => {swal('!',`Sorry! To protect this website from spam, please check the "I'm not a robot" box below.`,'warning')}} className='btn btn-primary mt-2'>Submit</Button>
                }

              </Form>
            </Card>
                </Col>
              </Row>


    <p><b>Why Choose KW Property Management?</b></p>

    <p>
      At KW Property Management, we understand the challenges and uncertainties faced by new investors. Navigating the complexities of property management, handling tenant relations, and understanding legal nuances can be overwhelming.
    </p>

    <p>
      Our comprehensive suite of services, tailored especially for novice investors, ensures you can enjoy a hands-off property experience.
    </p>

    <ol>
      <li class="mt-3">
        <b>Timely Rent Collection:</b> Are you worried about the timely collection of your rent? Our seamless rent collection process ensures that you get your rents promptly. Through our secure online payment portal, Dwello, tenants can easily make their payments, ensuring consistent cash flow for you.
      </li>
      <li class="mt-3">
        <b>Complete Leasing Solutions:</b> From effective digital advertising to tenant screenings, we handle it all. We ensure that your property is visible to the right audience and that you get reliable tenants.
      </li>
      <li class="mt-3">
        <b>Expertise in Handling Financial Challenges:</b> In those rare instances when rent collection becomes tricky, you have a dedicated team to recommend the best course of action.
      </li>
      <li class="mt-3">
        <b>Peace of Mind with Accounts Payable:</b> Rest easy knowing all property-related bills are managed efficiently. From invoice management to timely payments, we have you covered.
      </li>
      <li class="mt-3">
        <b>Emergency Support &amp; Conflict Resolution:</b> We prioritize a harmonious living environment. Our emergency support ensures tenant concerns are addressed promptly. Plus, with our expertise in mediation and conflict resolution, rest assured that tenant relations will be smooth.
      </li>
      <li class="mt-3">
        <b>Legal Guidance:</b> As a new investor, the legalities can be overwhelming. Our team provides essential guidance on legal notice distribution, eviction support, and more. From meticulous tenant screening to efficient rent collection, and from legal guidance to conflict resolution, our expert team is dedicated to simplifying your investment journey.
      </li>
    </ol>

    <p>
      From meticulous tenant screening to efficient rent collection, and from legal guidance to conflict resolution, our expert team is dedicated to simplifying your investment journey.
    </p>

    <p>
      We know that real estate investing can be really time consuming, that's where we come in. Let us introduce you to our services, where we can help provide top-tier, hands-off property management services tailored for your portfolio – no matter how big or small.
    </p>

    <p><b>Unlock the secrets of choosing the best property management team. Download our exclusive guide now Experience stress-free property management tailored to your needs.</b></p>

    <p><a href="https://www.kwproperty.com/Download/property_investors_checklist.pdf" target="_blank" rel="noreferrer">Get Your FREE Copy of the Property Investor’s Checklist Now!</a></p>

    <p><b>Ready to Elevate Your Investment Game?</b></p>

    <p>
      For a stress-free property management experience tailored to your needs, don't hesitate. Contact KW Property Management today and take the first step towards maximizing your property's potential.
    </p>

    <p><b>Frequently Asked Questions (FAQs):</b></p>

    <ul>
      <li class="mt-3">
        <b>Q</b>: I'm new to property investment; how can KW Property Management help me?
        <ul>
          <li class="mt-3">
            <b>A</b>: We offer comprehensive property management services, taking care of everything from rent collection to legal guidance. Our services are tailored for novice investors, ensuring a hands-off experience. Download our free, exclusive guide to learn how to choose the right property management company for you!
          </li>
        </ul>
      </li>

      <li class="mt-3">
        <b>Q</b>: How do you handle tenant conflicts?
        <ul>
          <li class="mt-3">
            <b>A</b>: Our team is skilled in mediation and conflict resolution, ensuring smooth tenant relations. We also offer emergency support for any immediate concerns.
          </li>
        </ul>
      </li>

      <li class="mt-3">
        <b>Q</b>: Are there any hidden fees in your services?
        <ul>
          <li class="mt-3">
            <b>A</b>: Transparency is key. All our fees are outlined in our packages. There are no hidden charges.
          </li>
        </ul>
      </li>

    </ul>

    <p>
      <b>Maximize Your Returns, Minimize Your Efforts!</b> With KW Property Management, get the best of both worlds. Get your hands on our guide and set yourself on the path to hands-off investment property success.
    </p>

    <p><a href="https://www.kwproperty.com/Download/property_investors_checklist.pdf" target="_blank" rel="noreferrer">Get Your FREE Copy of the Property Investor's Checklist Ebook now!</a></p>

             
                
                <h6 className='mt-2'>Our <span className='blue'>Mailing Address:</span> 541 Queen St S. Kitchener, ON N2G 1W9</h6>
                <h6>Phone <span className='blue'>Number:</span> 519-954-8082</h6>
                <h6>Email <span className='blue'>Address:</span> kwp@kwproperty.com</h6>
            </div>

        </div>

      </Container>
      <KWFooter />
    </>
  )
}
