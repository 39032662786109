import KWNavbar from '../../components/navbar/navbar'
import KWFooter from '../../components/footer/footer'
import ReCAPTCHA from 'react-google-recaptcha';
import config from '../../config.json'
import { LineWave } from 'react-loader-spinner';
import { IoIosCloseCircleOutline } from "react-icons/io";


import './ServiceRequest.css';
import { useEffect } from 'react';
import { useState } from 'react';
import swal from 'sweetalert';
import { Form } from 'react-bootstrap';

export const ServiceRequest = () => {
    const [properties, setProperties] = useState([]);
    const [selectedProperty, setSelectedProperty] = useState(null);
    const [isForThisServiceCallChecked, setisForThisServiceCallChecked] = useState(true)
    const [isTermsAndConditionsChecked, setTermsAndConditionsChecked] = useState(false)
    const [isIacknowledgeAndChecked, setIacknowledgeAndChanged] = useState(false)
    const [DueDateTime, setDueDateTime] = useState('')

    const [loading, setLoading] = useState(false);

    const TermsAndConditionsChanged = () => {
        setTermsAndConditionsChecked(!isTermsAndConditionsChecked)
    };

    const IacknowledgeAndChanged = () => {
        setIacknowledgeAndChanged(!isIacknowledgeAndChecked)
    };

    const handleDUeDateTime = (e) => {
        setDueDateTime(e.target.value)
    }

    const [countFile, setFileCount] = useState([])

    const [Captcha, setCaptcha] = useState(true)

    const handleCaptcha = (val) => {
        // console.log(val)
        val ? setCaptcha(true) : setCaptcha(false)
    }


    const [formData, setFormData] = useState({
        "YourName": '',
        "Address": '',
        "Phone": '',
        "City": '',
        "Email": '',
        "files": '',
        "PreferredMethodofContact": '',
        "WorkDetails": '',
        "VendorNotes": '',
        "DueDate": "",
        "EntryAllowed": "Yes",
        // "Task": {
        "Title": '',
        "PropertyId": 0,
        "UnitId": 0,
        // },
    });

    // formData.captcha = Captcha;
    formData.DueDate = DueDateTime;


    // const [file, setFile] = useState(null);

    // const onFileChange = (e) => {
    //     setFile(e.target.files[0]);

    //     handleFileUpload();
    // };


    // const handleRadioChange = (event) => {
    //     setTermsAccepted(event.target.value);
    // };

    const ForThisServiceCallChanged = (event) => {

        // console.log(event.target.checked)

        setFormData({
            ...formData,
            EntryAllowed: event.target.checked ? 'Yes' : 'No',
        });

        setisForThisServiceCallChecked(!isForThisServiceCallChecked)
    };

    const handlePropertyChange = (event) => {
        const selectedPropertyId = Number.parseInt(event.target.value);
        const selectedPropertyObject = properties.find(property => property.Property.Id === selectedPropertyId);
        setSelectedProperty(selectedPropertyObject);
        setFormData({
            ...formData,
            PropertyId: selectedPropertyId,
            UnitId: selectedPropertyObject ? selectedPropertyObject.Unit.Id : null
            // Task: {
            //     ...formData.Task,
            //     PropertyId: selectedPropertyId,
            //     UnitId: selectedPropertyObject ? selectedPropertyObject.Unit.Id : null,
            // }
        });
    }

    const handleYourName = (event) => {
        setFormData({
            ...formData,
            YourName: event.target.value,
            // Task: {
            //     ...formData.Task,
            // }
        });
    }

    const handleAddress = (event) => {
        setFormData({
            ...formData,
            Address: event.target.value,
            // Task: {
            //     ...formData.Task,
            // }
        });
    }
    const handlePhone = (event) => {
        setFormData({
            ...formData,
            Phone: event.target.value
        });
    }
    const handleCity = (event) => {
        setFormData({
            ...formData,
            City: event.target.value
        });
    }
    const handleEmail = (event) => {
        setFormData({
            ...formData,
            Email: event.target.value
        });
    }
    const handlePreferredMethodofContact = (event) => {
        setFormData({
            ...formData,
            PreferredMethodofContact: event.target.value
        });
    }
    const handleWorkDetailes = (event) => {
        setFormData({
            ...formData,
            WorkDetails: event.target.value
        });
    }

    const handleWorkTitle = (event) => {
        setFormData({
            ...formData,
            Title: event.target.value
        });
    }

    const submitWorkOrderFormOnly = async () => {
        try {
            if (formData.UnitId === 0) {
                swal('!', 'Please enter your unit number', "warning");
                return;
            }
            if (formData.YourName === '') {
                swal('!', 'Please enter your full name', "warning");
                return;
            }
            if (formData.Address === '') {
                swal('!', 'Please enter your full address', "warning");
                return;
            }
            if (formData.Phone === '') {
                swal('!', 'Please enter your phone number', "warning");
                return;
            }
            if (formData.Email === '') {
                swal('!', 'Please enter valid email address', "warning");
                return;
            }
            if (formData.PreferredMethodofContact === '') {
                swal('!', 'Please specify preferred contact method', "warning");
                return;
            }
            if (formData.Title === '') {
                swal('!', 'Please Enter Title of the work', "warning");
                return;
            }
            if (formData.WorkDetails === '') {
                swal('!', 'Please describe what work/service you require', "warning");
                return;
            }
            if (formData.DueDate === '') {
                swal('!', 'Please let us know a good time to book your service call for', "warning");
                return;
            }
            if (!isForThisServiceCallChecked) {
                if (isIacknowledgeAndChecked) {
                    setisForThisServiceCallChecked(true);
                } else {
                    swal('!', 'Please let us know if you need to be home or not for the contractor to perform the required tasks', "warning");
                    return;
                }
            }
            if (!isTermsAndConditionsChecked) {
                swal('!', 'You must accept the terms and conditions to submit a work order', "warning");
                return;
            }

            setLoading(true);
            // console.log(formData)
            const postData = new FormData();
            Object.keys(formData).forEach((fd) => {
                postData.append(fd, formData[fd]);
            });
            countFile.forEach((c) => {
                postData.append('files',c);
            });
            const response = await fetch(config.apiUrl + '/v1/workorders/workordersOutFiles', {
                method: 'POST',
                // headers: {
                //     'Content-Type': 'multipart/form-data',
                // },
                body: postData,
            });

            if (!response.ok) {
                setLoading(false);
                // Handle error here, throw an error, or log it
                swal('Error', 'Error in submitting work order form, please try later.', "warning");
                console.error('Error submitting form:', response.statusText);
                return;
            } else {
                setLoading(false);
                swal('Success', 'Work order submitted succesfully', "success");
                // Reset form data after successful submission
                setFormData({
                    "YourName": '',
                    "Address": '',
                    "Phone": '',
                    "City": '',
                    "Email": "",
                    "PreferredMethodofContact": '',
                    "WorkDetails": '',
                    "EntryAllowed": '',
                    // VendorId: config.emails.WorkOrder.VendorId,
                    "VendorId": "",
                    // "VendorNotes": '',
                    "DueDate": '',
                    // "Task": {
                    "Title": '',
                    "Priority": 'Low',
                    "Status": 'New',
                    "PropertyId": 0,
                    "UnitId": 0,
                    // AssignedToUserId: config.emails.WorkOrder.AssignedToUserId,
                    "AssignedToUserId": ""
                    // },
                });

                // Handle success, e.g., show a success message or redirect
                console.log('Form submitted successfully!');

            }
        } catch (error) {
            console.error('Error submitting form:', error);
            swal('Error', 'Error in submitting work order form, please try later.', "warning");
        }
    }



    // const submitWorkOrder = async () => {
    //     if (termsAccepted === 'no') {
    //         swal('Error', 'Please accept terms & conditions before submitting work order', "warning");
    //         return
    //     }
    //     try {
    //         const response = await fetch(config.apiUrl + '/v1/workorders', {
    //             method: 'POST',
    //             headers: {
    //                 'Content-Type': 'application/json',
    //             },
    //             body: JSON.stringify(formData),
    //         });

    //         if (!response.ok) {
    //             // Handle error here, throw an error, or log it
    //             swal('Error', 'Error submitting work order, please enter valid values', "warning");
    //             console.error('Error submitting form:', response.statusText);
    //             return;
    //         }

    //         swal('Success', 'Work order submitted succesfully', "success");

    //         // Reset form data after successful submission
    //         setFormData({
    //             WorkDetails: '',
    //             EntryAllowed: 'Unknown',
    //             // VendorId: config.emails.WorkOrder.VendorId,
    //             VendorId: "",
    //             VendorNotes: '',
    //             Task: {
    //                 Title: '',
    //                 DueDate: '',
    //                 Priority: 'Low',
    //                 Status: 'New',
    //                 PropertyId: 0,
    //                 UnitId: 0,
    //                 // AssignedToUserId: config.emails.WorkOrder.AssignedToUserId,
    //                 AssignedToUserId: ""
    //             },
    //         });

    //         // Handle success, e.g., show a success message or redirect
    //         console.log('Form submitted successfully!');
    //     } catch (error) {
    //         console.error('Error submitting form:', error);
    //     }
    // }

    const handleFileUpload = async (e) => {
        const fileMetadata = {
            EntityType: 'YourEntityType',
            EntityId: 123,
            FileName: countFile.name,
            Title: 'YourTitle',
            Description: 'YourDescription',
            CategoryId: 456,
        };


        try {
            const responseStep1 = await fetch(config.apiUrl + 'v1/files/uploadrequests', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(fileMetadata),
            });

            if (!responseStep1.ok) {
                throw new Error('Step 1: File metadata upload failed');
            }

            const { BucketUrl, FormData: formData } = await responseStep1.json();

            const formDataWithFile = new FormData();
            Object.entries(formData).forEach(([key, value]) => {
                formDataWithFile.append(key, value);
            });
            formDataWithFile.append('file', countFile);

            const responseStep2 = await fetch(BucketUrl, {
                method: 'POST',
                body: formDataWithFile,
            });

            if (responseStep2.ok) {
                console.log('File uploaded successfully!');
            } else {
                console.error('File upload failed.');
            }
        } catch (error) {
            console.error('Error during file upload:', error.message);
        }
    };

    const handleDocumentUploads = (e) => {
        if (countFile.length < 5) {
            setFileCount((prev) => {
                return [...prev,...e.target.files]
            })
        } else {
            swal('', 'Sorry You can\'t Upload more then 5 files', 'info');
        }
    }

    const removeFile = (indexNum) => {
        const newFiles = [...countFile]
        newFiles.splice(indexNum, 1)
        setFileCount(newFiles)
    }

    useEffect(() => {
        formData.files = countFile
    }, [countFile, formData])




    // const handleChange = (e) => {
    //     const name = e.target.name;
    //     let value = e.target.value;

    //     setFormData((data) => {
    //         return {
    //             ...data,
    //             [name]: value
    //         }
    //     })
    // }

    // const InputDate = new Date(formData.DueDate);

    // const formattedDueDate = InputDate.toLocaleString('en-US', {
    //     year: 'numeric',
    //     month: '2-digit',
    //     day: '2-digit',
    //     hour: '2-digit',
    //     minute: '2-digit',
    //     second: '2-digit',
    //     timeZoneName: 'short',
    //     timeZone: 'America/Toronto', // Use the specific timezone offset, in this case, Eastern Time (EST/EDT)
    // });

    // formData.DueDate = formattedDueDate;

    console.log(formData)


    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const response = await fetch(config.apiUrl + '/v1/rentals/units/listings', {});
                const data = await response.json();
                // console.log(data);
                setProperties(data);
                setLoading(false);
            } catch (error) {
                setLoading(false);
                console.error('Error fetching leased units:', error);
            }
        }
        fetchData();
    }, []);


    return (
        <>
            <KWNavbar></KWNavbar>

            <div>
                {
                    loading !== true ?

                        <div className="serviceRequest-container mt-5 pt-5">
                            <div className="mt-5">
                                <div className="service-header">Work Order Request Form</div>
                                <div className="service-para">
                                    Please fill out the work order request form and we will respond to your needs quickly and efficiently. If you have any questions or concerns regarding your work order, please don't hesitate to contact us through the means below.
                                </div>
                                <div className="service-H_details">
                                    <div className="">Phone : 519-954-8082</div>
                                    <div className="">Email : <a href="">maintenance@kwproperty.com</a></div>
                                </div>
                                <div className="service-form">
                                    <div className='border rounded p-3 mt-3'>
                                        <div
                                            className='fs-4 justify-content-between d-flex'
                                            data-bs-toggle="collapse"
                                            data-bs-target="#Unitcontactinformation"
                                            style={{ cursor: "pointer" }}
                                        >
                                            <div><b className='service-form-para2-H'>Unit & contact information</b></div>
                                            <div className="">
                                                <img style={{ width: "15px" }} src="https://cdn-icons-png.flaticon.com/512/25/25629.png" alt="" />
                                            </div>
                                        </div>
                                        <div className="collapse" id="Unitcontactinformation">
                                            <div className="input-div">
                                                <label className='service-form-label' htmlFor="YourName">Unit*</label>
                                                <div className="select-div">
                                                    {/* <div className='service-form-label2'>Unit</div> */}
                                                    <select name="" id="" className='service-form-input required-number required-number2' onChange={handlePropertyChange}>
                                                        <option value="">Please Select</option>
                                                        {/* {properties.map((data, index) => (
                                                // <option key={index} value={property.PropertyId}>
                                                //     {property.UnitNumber}
                                                // </option>

                                                const unitNumb = data.Unit?.UnitNumber
                                                    let unitNumber = '';
                                                    if (unitNumb.indexOf('[') > 0) {
                                                        unitNumber = unitNumb.substring(0, unitNumb.indexOf('['))
                                                    } else if (
                                                        unitNumber = unitNumb
                                                    )

                                                <option key={index}  value={`${data.Property.Id}/${data.Unit.Id}`}>{data.Property.Name} {unitNumber}</option>
                                            ))} */}


                                                        {
                                                            properties.length > 0 ? (
                                                                properties.map((data) => {
                                                                    const unitNumb = data.Unit?.UnitNumber
                                                                    let unitNumber = '';
                                                                    if (unitNumb.indexOf('[') > 0) {
                                                                        unitNumber = unitNumb.substring(0, unitNumb.indexOf('['))
                                                                    } else if (
                                                                        unitNumber = unitNumb
                                                                    )
                                                                        return (
                                                                            <>
                                                                                <option value={`${data.Property.Id}/${data.Unit.Id}`}>{data.Property.Name} {unitNumber}</option>
                                                                            </>
                                                                        )
                                                                })
                                                            ) : ''
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="input-div">
                                                <label className='service-form-label' htmlFor="YourName">Your Name*</label>
                                                <input value={formData.YourName} onChange={handleYourName} className='service-form-input required-number required-number2' type="text" name="YourName" id="YourName" />
                                            </div>
                                            <div className="input-div">
                                                <label className='service-form-label' htmlFor="Address">Your Address*</label>
                                                <input value={formData.Address} onChange={handleAddress} className='service-form-input required-number required-number2' type="text" name="Address" id="Address" />
                                            </div>
                                            <div className="input-div">
                                                <label className='service-form-label' htmlFor="Phone">Phone*</label>
                                                <input value={formData.Phone} onChange={handlePhone} className='service-form-input required-number required-number2' type="number" name="phone" id="Phone" />
                                            </div>
                                            <div className="input-div">
                                                <label className='service-form-label' htmlFor="City">City*</label>
                                                <input value={formData.City} onChange={handleCity} className='service-form-input required-number required-number2' type="text" name="City" id="City" />
                                            </div>
                                            <div className="input-div">
                                                <label className='service-form-label' htmlFor="Email">Email Address*</label>
                                                <input value={formData.Email} onChange={handleEmail} className='service-form-input form-control required-number required-number2' type="text" name="email" id="Email" />
                                            </div>
                                            <div className="input-div">
                                                <label className='service-form-label' htmlFor="PreferredMethodofContact">Preferred method of contact?</label>
                                                <div className="select-div">
                                                    {/* <div className='service-form-label2'>Preferred method of contact?</div> */}
                                                    <select value={formData.PreferredMethodofContact} onChange={handlePreferredMethodofContact} name="" id="" className='service-form-input required-number required-number2'>
                                                        <option value="">Please Select</option>
                                                        <option value="Call">Call</option>
                                                        <option value="Text">Text</option>
                                                        <option value="Email">Email</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='border rounded p-3 mt-3'>
                                        <div
                                            className='fs-4 justify-content-between d-flex'
                                            data-bs-toggle="collapse"
                                            data-bs-target="#Servicerequired"
                                            style={{ cursor: "pointer" }}
                                        >
                                            <div><b>Work / Service required</b></div>
                                            <div className="">
                                                <img style={{ width: "15px" }} src="https://cdn-icons-png.flaticon.com/512/25/25629.png" alt="" />
                                            </div>
                                        </div>
                                        <div className="collapse" id="Servicerequired">
                                            <div className='mt-2 input-div'>
                                                <div className='service-form-label2'>Title of the work</div>
                                                <input value={formData.Title} className='service-form-input required-number required-number2' onChange={handleWorkTitle} type="text" name="Title" id="Title" />
                                            </div>
                                            <div className="">
                                                <label className='service-form-label' style={{ opacity: 0 }} htmlFor="YourName">Your Name*</label>
                                                <div className="select-div">
                                                    <div className='mb-2 mt-2'><b>Please describe the work or service required:</b></div>
                                                    <div className="PleaseDescribe">
                                                        If you're dealing with an <b style={{ color: "#0c5460" }}>appliance issue</b> please specify the <b style={{ color: "#0c5460" }}>make</b> and <b style={{ color: "#0c5460" }}>model</b> so we can better serve you.
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="">
                                                <label className='service-form-label' style={{ opacity: 0 }} htmlFor="YourName">Your Name*</label>
                                                <div>
                                                    <textarea value={formData.WorkDetails} onChange={handleWorkDetailes} className='service-form-input2 required-number required-number2' name="" id="" cols="20" rows="3" maxLength="250"></textarea>
                                                    <br />
                                                    <small style={{ opacity: 0.6 }}>250 characters remaining</small>
                                                </div>
                                            </div>

                                            {/* <div className='mt-2'>
                                    <div className='service-form-label2'>Enter Vendor Notes</div>
                                    <textarea onChange={handleVendorNotes} className='service-form-input2 required-number required-number2' name="" id="" cols="20" rows="3" maxLength="250"></textarea>
                                </div> */}

                                            <div className="input-div">
                                                <div className='service-form-label2'>Requested date/time for service call:</div>
                                                {/* <input value={formData.DueDate} onChange={handleChange} className='service-form-input required-number required-number2' type="datetime-local" name="DueDate" id="" /> */}
                                                <input type="date" value={formData.DueDate} onChange={handleDUeDateTime} className='service-form-input required-number required-number2' name="DueDateTime" id="" />
                                            </div>
                                            <div className="">
                                                <label className='service-form-label' style={{ opacity: 0 }} htmlFor="YourName">Your Name*</label>
                                                <div className=''>
                                                    <div className='service-form-label2'>Do you need to be home for this service call?</div>
                                                    <div className="ms-2">
                                                        {/* <div className="d-flex align-items-center mx-2">
                                                <input type="radio" name="doYouNeed" id="doYouNeed1" className='doYouNeed2' /><label htmlFor="doYouNeed1" className='ms-2 service-form-label2 cursor-pointer'>Yes</label>
                                            </div>
                                            <div className="d-flex align-items-center mx-2">
                                                <input type="radio" name="doYouNeed" id="doYouNeed2" className='doYouNeed2' /><label htmlFor="doYouNeed2" className='ms-2 service-form-label2 cursor-pointer'>No</label>
                                            </div> */}
                                                        <Form>
                                                            <Form.Check
                                                                type="switch"
                                                                id="custom-switch"
                                                                checked={isForThisServiceCallChecked}
                                                                onChange={ForThisServiceCallChanged}
                                                            />
                                                            {
                                                                isForThisServiceCallChecked ?
                                                                    <div className='YesNoDropdown'>
                                                                        <div className='YesNoButton'>Yes</div>
                                                                    </div>
                                                                    : <div className='YesNoDropdown'>
                                                                        <div className='YesNoButton'>No</div>
                                                                    </div>
                                                            }
                                                        </Form>
                                                        {
                                                            !isForThisServiceCallChecked ?
                                                                <div className='alert alert-info mt-2 d-flex'>
                                                                    <Form>
                                                                        <Form.Check
                                                                            type="switch"
                                                                            id="custom-switch"
                                                                            checked={isIacknowledgeAndChecked}
                                                                            onChange={IacknowledgeAndChanged}
                                                                            className='serviceCall_check'
                                                                        />
                                                                    </Form>
                                                                    <div className="fs-6 fw-bold">I acknowledge and allow a tradesperson to enter the premises to carry out the repairs required.</div>
                                                                </div> : ""
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="">
                                                <div class="form-group">
                                                    {/* <div class="custom-file mt-2">
                                            <input className='custom-file-input' type="file" name="customFile" id="customFile" onChange={onFileChange} />
                                            <label class="custom-file-label" for="customFile">Drop photos here or click to upload</label>
                                        </div> */}
                                                    <div className="FileUploadHeader">Have any photos to show us?</div>
                                                    <div className="row mb-3">
                                                        <div className="col-md-12">
                                                            {/* <label className='fw-bold mb-0 pb-0'>
                                                    Photo ID, Proof of Income, and Credit Report (At least 3 documents required up to 5 max. We accept images and PDF's).{" "}
                                                </label> */}
                                                            <div style={{ position: "relative" }} className="form-group">
                                                                <div style={{ height: 0, overflow: "hidden", width: "100%" }}>
                                                                    <input
                                                                        type="text"
                                                                        id="applicant-document-count"
                                                                        name="applicant-document-count"
                                                                        defaultValue={0}
                                                                        className="document-count w-100"
                                                                        fdprocessedid="3yk7cj"
                                                                    />
                                                                </div>
                                                                <div
                                                                    className="filepond--root my-pond w-100 filepond--hopper custom-file"
                                                                    id="applicant-documents"
                                                                    data-style-button-remove-item-position="left"
                                                                    data-style-button-process-item-position="right"
                                                                    data-style-load-indicator-position="right"
                                                                    data-style-progress-indicator-position="right"
                                                                    data-style-button-remove-item-align="false"
                                                                    style={{ height: 76, position: "relative" }}
                                                                >
                                                                    <input
                                                                        className='custom-file-input filepond--browser'
                                                                        type="file"
                                                                        id="filepond--browser-wk65r38pt"
                                                                        name="applicantdocuments"
                                                                        onChange={handleDocumentUploads}
                                                                        aria-controls="filepond--assistant-wk65r38pt"
                                                                        aria-labelledby="filepond--drop-label-wk65r38pt"
                                                                        accept="image/gif,image/png,image/jpeg,application/pdf" multiple/>
                                                                    <div
                                                                        className="filepond--drop-label"
                                                                        style={{ transform: "translate3d(0px, 0px, 0px)", opacity: 1 }}
                                                                    >
                                                                        <label
                                                                            className='custom-file-label'
                                                                            htmlFor="filepond--browser-wk65r38pt"
                                                                            id="filepond--drop-label-wk65r38pt"
                                                                            aria-hidden="true"
                                                                        >
                                                                            Drag & Drop your files or{" "}
                                                                            <span className="filepond--label-action" tabIndex={0}>
                                                                                Browse
                                                                            </span>
                                                                        </label>
                                                                    </div>

                                                                </div>
                                                                <div className="error-msgs" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {
                                                        countFile.length > 0 ? (
                                                            countFile.map((f, index) => (
                                                                <>
                                                                    <div className='w-100 h-auto d-flex justify-content-between p-2 border my-2 rounded bggreen500'>
                                                                        <h6 className="name">{f.name}</h6>
                                                                        <div className='cursor-pointer'>
                                                                            <IoIosCloseCircleOutline onClick={() => removeFile(index)} size={30} />
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            ))
                                                        ) : ''
                                                    }
                                                </div>
                                            </div>
                                            {/* <div className="">
                                    <label className='service-form-label' style={{ opacity: 0 }} htmlFor="YourName">Your Name*</label>
                                    <div className="PleaseDescribe">
                                        Have any photos to show us?
                                    </div>
                                </div> */}
                                        </div>
                                    </div>
                                    <div className="border rounded p-3 mt-3">
                                        <div
                                            className='fs-4 justify-content-between d-flex'
                                            data-bs-toggle="collapse"
                                            data-bs-target="#TermsandConditions"
                                            style={{ cursor: "pointer" }}
                                        >
                                            <div><b className='service-form-para2-H'>Terms and Conditions</b></div>
                                            <div className="">
                                                <img style={{ width: "15px" }} src="https://cdn-icons-png.flaticon.com/512/25/25629.png" alt="" />
                                            </div>
                                        </div>
                                        <div className="" id="TermsandConditions">
                                            <div className="">
                                                <label className='service-form-label' style={{ opacity: 0 }} htmlFor="YourName">Your Name*</label>
                                                <div className="service-form-para2">
                                                    <div className="service-form-para2-text">
                                                        Tenants please be advised you will be charged for a service call if the following work order is a result of tenant neglect or damage. Also including below but not limited to:
                                                    </div>
                                                    <div className="service-form-para2-text">
                                                        <b>CLOGGED SINKS -</b> This service call is a charge to the tenants if the pipes are full of debris. The tenant will not be charged if the pipe is structurally compromised
                                                    </div>
                                                    <div className="service-form-para2-text">
                                                        <b>NO HYDRO -</b> The tenant will be charged for this service call if the breakers are off or fuses are burnt out. The tenant will also be charged for this service call if the receptacle reset button needs to be pressed to reset. Please ensure you have checked breakers, fuses and reset buttons prior to our company sending out an electrician. This will avoid costs to you.
                                                    </div>
                                                    <div className="service-form-para2-text">
                                                        FURNACE NOT WORKING - Please make sure the outside vent (exhaust vent) is not clogged by debris or ice or snow. If the HVAC technician arrives on site and determines the cause to be a blocked vent, the tenant will be held responsible for the service call. The tenant will also be held responsible for the service call if the batteries on the thermostat are low, or a dirty furnace prevented the furnace from working (Furnace filters should be changed at least every 3 months).
                                                        <b>
                                                            Make sure Furnace and or AC switch is turned on, or the tenant will be held responsible for the service call.
                                                        </b>
                                                    </div>
                                                    <div className="service-form-para2-text">
                                                        <b>LIGHT BULBS -</b> The tenant is responsible for changing all burnt out light bulbs.
                                                    </div>
                                                    <div className="service-form-para2-text">
                                                        <b>NO ACCESS GRANTED -</b> Please be sure if you request to be home during the service call you are in fact there. If you are not there to grant access after you specifically ask for this service, you will be charged for the service call.
                                                    </div>
                                                    <div className="service-form-para2-text">
                                                        <b>WATER SOFTENER -</b> If your work order is in regards to your water softener running continuously, please put the water softener on bypass by either turning the valve manually or on the program screen selecting bypass. If you cannot determine the bypass please call our office immediately.
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-2 ps-2">
                                        <div className='mb-3'>
                                            <div className='service-form-label2'>I accept these terms and conditions:</div>
                                            <Form>
                                                <Form.Check
                                                    type="switch"
                                                    id="custom-switch"
                                                    checked={isTermsAndConditionsChecked}
                                                    onChange={TermsAndConditionsChanged}
                                                />
                                                {
                                                    isTermsAndConditionsChecked ?
                                                        <div className='YesNoDropdown'>
                                                            <div className='YesNoButton'>Yes</div>
                                                        </div>
                                                        : <div className='YesNoDropdown'>
                                                            <div className='YesNoButton'>No</div>
                                                        </div>
                                                }
                                            </Form>
                                        </div>
                                        <div className="service-form-label2 mb-2">To protect this website from spam, please check the box below.</div>
                                        <div>
                                            <ReCAPTCHA
                                                sitekey={config.reCaptchaKey}
                                                onChange={handleCaptcha}
                                            />
                                        </div>
                                        {
                                            Captcha ?
                                                <div className="mt-3">
                                                    <button type='button' className='Property-Currently-button' onClick={submitWorkOrderFormOnly}>Submit</button>
                                                </div> :
                                                <div className="mt-3">
                                                    <button type='button' className='Property-Currently-button' onClick={() => {
                                                        swal('!', `Sorry! To protect this website from spam, please check the "I'm not a robot" box below. Please try again.`, 'warning')
                                                    }}>Submit</button>
                                                </div>
                                        }
                                        {/* <button type='button' className='Property-Currently-button' onClick={submitWorkOrderFormOnly}>Submit</button> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        <div className='lineWave_main'>
                            <div className="lineWave" >
                                <LineWave
                                    height="200px"
                                    width="300px"
                                    color="#0586f0"
                                    ariaLabel="line-wave"
                                    wrapperStyle={{}}
                                    wrapperClass=""
                                    visible={true}
                                    firstLineColor=""
                                    middleLineColor=""
                                    lastLineColor=""
                                />
                            </div >
                        </div>
                }
            </div>

            <KWFooter></KWFooter>
        </>
    )
}