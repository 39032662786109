import KWNavbar from '../../components/navbar/navbar'
import KWFooter from '../../components/footer/footer'
import './Showing.css';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { LineWave } from 'react-loader-spinner';
import Moment from 'react-moment';
import moment from 'moment-timezone';
import 'moment-timezone'

// const { format } = require('date-fns-tz');

import config from '../../config.json'
import { useNavigate } from 'react-router-dom';

import { Link } from 'react-router-dom';
Moment.globalTimezone = 'America/Toronto'

export const Showing = () => {
    const [isLoading, setIsloading] = useState(true)
    const [itemData, setItemData] = useState({});
    const navigate = useNavigate()
    const fetchData = () => {
        axios.get(config.apiUrl + '/v1/calender/events').then((res) => {

            return res.data.groupedEvents;
        })
            .then((data) => {
                setItemData(data)
                console.log(data)
                setIsloading(false)
            })
            .catch(() => {
                setIsloading(false)
            })
    };
    useEffect(() => {
        fetchData()
    }, []);
    const handleNavigate = async (id, bookData) => {
        fetch(config.apiUrl + `/v1/rentals/units/${id}/listing`, {
            
        }).then(async (resp) => {
            if (resp.ok) {
                const data = await resp.json();
                return data
            }
        }).then((data) => {
            navigate("/listing", { state: { listing: data, dateTime: bookData, id } })
        })


    }
    const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
    return (
        <>
            <KWNavbar />
            <div className="pt-5">
                <div className="container showing-container">
                    <div className="mt-sm-3 mt-md-5 pt-1 px-2">
                        <div className="col-sm-12 col-md-10">
                            <div className="h1 pt-5 Upcoming-Propertyhead">Upcoming Property Showings</div>
                            <div className="mt-4 pt-4 Upcoming-property">Upcoming property showings are displayed below. If you would like to attend any, please click the corresponding 'BOOK SHOWING' link to confirm. Don't see the property you're interested in? No problem.
                                <Link to="/ScheduleShowing" className="Upcoming-property2" style={{marginLeft: '5px'}}>
                                    Click here to book an appointment for another property.
                                </Link>
                                {/* <a href="/ScheduleShowing" className='Upcoming-property2'> Click here to book an appointment for another property.</a> */}
                                {/* <a href="#" className='Upcoming-property2'> Click here to book an appointment for another property.</a> */}
                            </div>
                        </div>
                        <div className="col-sm-12">
                            {
                                isLoading !== true ?
                                    itemData ?
                                        Object.keys(itemData)?.map((key) => {
                                            const cDay = new Date(key)
                                            cDay.toLocaleString('en-US', { timeZone: 'America/Toronto' })
                                            console.log(itemData)
                                            let d = new Date();
                                            d.toLocaleString('en-US', { timeZone: 'America/Toronto' })
                                            const today = `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`;
                                            const keyDate = key.split('-').reduce((a, b) => Number(a) + Number(b),)
                                            const td = today.split('-').reduce((a, b) => Number(a) + Number(b),)
                                            console.log(td);
                                            console.log(keyDate);
                                            const title = td === keyDate ? 'Today' : `${daysOfWeek[cDay.getDay()]} , ${months[cDay.getMonth('m')]} ${cDay.getDate()}  ${cDay.getFullYear()}`


                                            const timeZone = 'America/Toronto';

                                            // Convert the date to the desired time zone using moment-timezone
                                            const formattedDate = moment.tz(key, timeZone);

                                            // Check if the date is today or tomorrow
                                            const isToday = moment().isSame(formattedDate, 'day');
                                            const isTomorrow = moment().add(1, 'day').isSame(formattedDate, 'day');

                                            // Set the formatted date based on the check
                                            let displayDate;
                                            if (isToday) {
                                                displayDate = 'Today';
                                            } else if (isTomorrow) {
                                                displayDate = 'Tomorrow';
                                            } else {
                                                displayDate = formattedDate.format('MMM DD, YYYY');
                                            }

                                            return (
                                                <>
                                                    <div className="secound-content mt-5">
                                                        <div className="content2-head">{displayDate}</div>
                                                        <div className="content2-border"></div>
                                                        <div className="content2-row">
                                                            {
                                                                    itemData[key].map((item) => {
                                                                        if (item.details !== undefined) {
                                                                            const labl = new Date(item.start.dateTime);
                                                                            labl.toLocaleString('en-US', { timeZone: item.start.timeZone })
                                                                            let bookData = {
                                                                                title: title,
                                                                                summery: item.details?.summery,
                                                                                time: item.start.dateTime,
                                                                                eventId: item.id
                                                                            }
                                                                            return (
                                                                                <div className="row mt-2">
                                                                                    <div className="col-sm-12 col-md-2 time">
                                                                                        <Moment date={item.start.dateTime} format="hh:mm a" />
                                                                                    </div>
                                                                                    <div className="col-sm-12 col-md-6">
                                                                                        <p
                                                                                            style={{ cursor: 'pointer' }}
                                                                                            onClick={() => navigate(`/listing/${item.description}`)} className='middle-link1'>{item.details.summery}</p>

                                                                                        {/* <div className='content2-unit'>Unit C</div> */}
                                                                                    </div>
                                                                                    <div className="col-sm-12 col-md-4">
                                                                                        {
                                                                                            item.details.meetingStatus === "c" ? (
                                                                                                <button className='book-Showing-c btn btn-danger'>CANCELLED</button>
                                                                                            ) : (
                                                                                                <button className='book-Showing btn btn-primary' onClick={() => navigate(`/ConfirmProperty/${item.id}`, { state: { bookData } })}>BOOK SHOWING</button>
                                                                                            )
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        }

                                                                    }) 
                                                            }
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        })
                                        : ('')
                                    : (
                                        <div className="d-flex justify-content-center">
                                            <LineWave
                                                height="200px"
                                                width="300px"
                                                color="#0586f0"
                                                ariaLabel="line-wave"
                                                wrapperStyle={{}}
                                                wrapperClass=""
                                                visible={true}
                                            />
                                        </div>
                                    )
                            }
                        </div>
                    </div>
                </div>
            </div>
            <KWFooter />
        </>
    )
}